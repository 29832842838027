import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {useAuth} from "../provider/AuthProvider";
import "./LandingScreen.css";
import {useNavigate} from "react-router-dom";
import useNotification from "../hooks/useNotification";
import Menu from "../components/Menu";
import {Box} from "@mui/material";
import {useLanguage} from "../provider/LanguageProvider";

export const LandingScreen = () => {
	const {addNotification} = useNotification();
	const {currentUser} = useAuth();
	const [loading, setLoading] = useState();

	const navigate = useNavigate();
	const {language} = useLanguage();

	useEffect(() => {}, [loading]);

	return (
		<div>
			<div className='landingpage'>
				<Grid container component='main' sx={{height: "100vh"}}>
					<Grid
						item
						xs={12}
						sm={4}
						md={7}
						lg={8}
						sx={{
							minHeight: {xs: "30vh", sm: "100vh"},
							height: {sm: "100vh"},

							backgroundColor: "#fffaeb",
						}}
					>
						<Box
							sx={{
								backgroundImage: {
									xs: "url(images/landingpage0.5.jpg)",

									sm: "url(images/landingpage.jpg)",
								},
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								backgroundPosition: {
									sm: "40%",
									md: "center",
								},
								position: {
									xs: "relative",
									sm: "fixed",
								},
								width: {
									xs: "100vw",
									sm: "32vw",
									md: "57vw",
									lg: "66vw",
								},
								height: {
									xs: "30vh",
									sm: "100vh",
									md: "100vh",
									lg: "100vh",
								},
							}}
							id='imagebox'
						></Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={8}
						md={5}
						lg={4}
						sx={{
							width: {
								xs: "100vw",
								sm: "67vw",
								md: "42vw",
								lg: "33vw",
							},

							backgroundColor: "#fffaeb",
							zIndex: 10,
							paddingBottom: "100px",
						}}
						id='gridright'
					>
						<Box
							sx={{
								width: "160px",
								height: "160px",
								margin: "50px auto 50px auto",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage: "url(images/logo-transparant.png)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "100%",
								position: "relative",
								zIndex: "-1",
							}}
						></Box>
						<Box
							sx={{
								padding: {
									xs: "0px 10px 0px 10px",
									sm: "0px 20px 0px 20px",
								},
							}}
						>
							<h2 className='landing_heading-names'>
								<span className='landing_heading-names-iris'>Iris</span>
								<span className='landing_heading-names-and'>&</span>
								<span className='landing_heading-names-robin'>Robin</span>
							</h2>
							<h3 className='landing_heading-date'>
								02 <span>.</span> 09 <span>.</span> 23
							</h3>
							<h2 className='header'>
								{language === "dutch" ? "Welkom" : "Welcome"}
							</h2>
							{language === "dutch" ? (
								<p className='landing-paragraph'>
									Op 2 september is het zover, dan gaan wij trouwen! Laat jij
									ook snel weten of je komt? Maak daarvoor een account aan met
									de code die op je uitnodiging staat.
								</p>
							) : (
								<p className='landing-paragraph'>
									September second is our date, we are getting married! Will you
									let us know if you are able to attend? To do so, make an
									account with the personal code written on the invitation.
								</p>
							)}
							<button
								className='landing-button'
								onClick={() => navigate("/register")}
							>
								{language === "dutch" ? "Maak account" : "Make account"}
							</button>

							<p className='landing-paragraph'>
								{language === "dutch"
									? "Of heb je al een account en wil je weer inloggen?"
									: "Or do you already have an account and just want to login again?"}
							</p>

							<button
								className='landing-button'
								onClick={() => navigate("/login")}
							>
								Log in
							</button>

							<p className='landing-paragraph'>
								Want to view this page in English? Use the button in the menu.
							</p>
						</Box>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
