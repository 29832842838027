import {Button, Link} from "@mui/material";
import React from "react";
import "./AdminMenu.css";
import {getAuth, signOut} from "firebase/auth";
import useNotification from "../../hooks/useNotification";
import {useNavigate} from "react-router-dom";

export default function AdminMenu() {
	const {addNotification} = useNotification();
	const navigate = useNavigate();
	const logoutHandler = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				addNotification("Succesvol uitgelogd", "success");
				navigate("/");
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	};
	return (
		<div className='menubar'>
			<Link href={"/admin/addguest"} underline='none'>
				addguest
			</Link>
			<Link href={"/admin/main"} underline='none'>
				main
			</Link>
			<Link href={"/admin/guestlist"} underline='none'>
				guestlist
			</Link>
			<Button onClick={logoutHandler}>Logout</Button>
		</div>
	);
}
