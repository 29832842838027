import {BrownButton} from "../../components/BrownButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {useAuth} from "../../provider/AuthProvider";
import {useState} from "react";
import axios from "axios";

import useNotification from "../../hooks/useNotification";
import {auth} from "../../helpers/FirebaseInit";

export const AdminCreateUser = () => {
	const {signup} = useAuth();

	const {addNotification} = useNotification();
	const [name = "", setName] = useState();
	const [otp, setOtp] = useState("");
	const [newUsers, setNewUsers] = useState([]);
	const userSuccesfullyCreatedFunction = () => {
		addNotification("Nieuwe gebruiker geregistreerd.", "info");
		setName("");
	};
	const handleSubmit = async (event) => {
		event.preventDefault();

		const getAuthToken = async () => {
			try {
				return "Bearer " + (await auth.currentUser?.getIdToken());
			} catch (err) {
				console.log("getAuthToken", err);
			}
		};

		const token = await getAuthToken();

		axios
			.post(
				"http://127.0.0.1:5001/bruiloft0209/us-central1/createUser",
				{
					username: `${name}`,
					password: "password",
					otp: `${otp}`,
				},
				{headers: {token}}
			)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});

		// signup(name);
		userSuccesfullyCreatedFunction();
		console.log("function called");
	};

	return (
		<div className='page2'>
			<Grid container component='main' sx={{height: "100vh"}}>
				<CssBaseline />
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					sx={{
						minHeight: {xs: "30vh", sm: "100vh"},
						backgroundImage: {
							xs: "url(../images/landing-picture-0.5.jpg)",
							sm: "url(../images/landing-picture.jpg)",
						},
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light"
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
				<Grid item xs={12} sm={6} md={6} elevation={6} square>
					Create User screen
					<div className='form'>
						<Box
							component='form'
							noValidate
							onSubmit={handleSubmit}
							sx={{mt: 1}}
						>
							<TextField
								fullWidth
								id='standard-basic'
								label='email'
								variant='standard'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<BrownButton
								type='submit'
								fullWidth
								variant='contained'
								sx={{mt: 3, mb: 2}}
							>
								Log in
							</BrownButton>
						</Box>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
