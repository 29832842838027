import {Box, Typography} from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsSubwayIcon from "@mui/icons-material/DirectionsSubway";

import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";
import {Link} from "react-router-dom";

export const ParkerenScreen = () => {
	const {language} = useLanguage();
	return (
		<Layout>
			<Box
				sx={{
					padding: {
						xs: "0px 20px 100px 20px",
						sm: "200px 30px 100px 30px",
					},
				}}
				className='parkeren_body'
			>
				<div className='parkeren_header-container'>
					<div className='parkeren_header-background'></div>
					<h2 className='parkeren_header'>
						{language === "dutch" ? "Parkeren" : "Parking"}
					</h2>
				</div>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<DirectionsCarIcon
						sx={{
							marginRight: "20px",
						}}
					/>
					<Typography
						sx={{
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						{language === "dutch" ? "Auto" : "By car"}
					</Typography>
				</Box>
				{language === "dutch" ? (
					<>
						<p className='parkeren_paragraph'>
							Op onze trouwlocatie is genoeg parkeergelegenheid. Let er echter
							op dat je je auto daar ‘s nachts niet kunt laten staan. Het
							landgoed sluit na afloop van het feest en blijft op zondag
							gesloten. Het hek gaat dan op maandag pas weer open.
						</p>

						<p className='parkeren_paragraph'>
							Als je in de buurt overnacht, dan kun je je auto beter alvast bij
							het hotel neerzetten. Meer informatie over de hotelopties kun je
							vinden onder de kop ‘Overnachten’.
						</p>
					</>
				) : (
					<>
						<p className='parkeren_paragraph'>
							There is ample space to park at our wedding location. However,
							please be aware that it is not possible to leave your car there
							overnight. The gate closes after the party ends, and does not
							reopen until Monday morning.
						</p>

						<p className='parkeren_paragraph'>
							If you decide to stay at the hotel, we advise you to park your car
							there and walk over to the wedding location. For hotel options,
							please see the page 'Accommodation' in the menu.
						</p>
					</>
				)}

				<Box
					sx={{
						maxWidth: "100%",
						height: "300px",
						margin: "0px 0px 60px 0px",
						overflow: "hidden",
						borderRadius: "10px",

						backgroundImage:
							"url(https://www.toptrouwlocaties.nl/Uploads/Cache/Files/220223161637.Landgoed-de-Salentein---Entree-op-bordes.resized.1280x0.jpg)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						opacity: "90%",
						position: "relative",
						zIndex: "-1",
					}}
				></Box>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<DirectionsSubwayIcon
						sx={{
							marginRight: "20px",
						}}
					/>
					<Typography
						sx={{
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						{language === "dutch" ? "Openbaar vervoer" : "Public transport"}
					</Typography>
				</Box>
				{language === "dutch" ? (
					<>
						<p className='parkeren_paragraph'>
							Met het openbaar vervoer kun je gemakkelijk tot het treinstation
							in Nijkerk komen. Het vervoer van het treinstation naar de
							Salentein is onregelmatiger, en er zijn geen ov-fietsen
							beschikbaar. Wij raden jullie aan je reis vooraf te plannen,
							eventueel te carpoolen of een taxiritje te boeken. Vanaf het
							station in Nijkerk is het nog 5 minuten rijden naar de
							trouwlocatie. ‘s Avonds gaat de laatste trein van Nijkerk naar
							Utrecht om 00:34. Om van de Salentein naar het station, naar een
							hotel of naar huis te komen, kunnen jullie gebruik maken van
							taxidienst Amersfoort.
						</p>
						<p className='parkeren_paragraph'>
							Voor taxiritten kunnen jullie contact opnemen met taxidienst
							Amersfoort. Voor ongeveer €37 euro per rit {"("}binnen Nijkerk
							{")"} brengen zij je van of naar je hotel. Taxidienst Amersfoort
							is te bereiken via:{" "}
							<Link to={"mailto:info@taxidienstamersfoort.nl"}>
								info@taxidienstamersfoort.nl
							</Link>{" "}
							of 06-20808011.
						</p>
					</>
				) : (
					<>
						<p className='parkeren_paragraph'>
							You can easily reach the train station in Nijkerk using public
							transportation. However, public transport from the treinstation to
							see if wedding location is less regular, and there’s no public
							bikes available. Therefore, we recommend planning your journey in
							advance, consider carpooling or book a taxi. From the train
							station in Nijkerk, it’s a five minute drive to our wedding
							location. At night, the last train from Nijkerk to Utrecht departs
							at 00:34.
						</p>
						<p className='parkeren_paragraph'>
							To book a taxi, you can contact taxidienst Amersfoort. For a price
							of around €37 they will bring you or from your hotel, the
							trainstation or any other location within Nijkerk. You can reach
							them via:{" "}
							<Link to={"mailto:info@taxidienstamersfoort.nl"}>
								info@taxidienstamersfoort.nl
							</Link>{" "}
							or 06-20808011.
						</p>
					</>
				)}
			</Box>
		</Layout>
	);
};
