import {Box} from "@mui/material";
import {useAuth} from "../../provider/AuthProvider";
import {useNavigate} from "react-router-dom";
import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";

export const HomeScreen = () => {
	const {userData} = useAuth();
	const {language} = useLanguage();
	const navigate = useNavigate();

	return (
		<Layout>
			<Box
				sx={{
					padding: {
						xs: "0px 20px 0px 20px",
						sm: "0px 50px 0px 30px",
					},
				}}
			>
				<div>
					<Box
						sx={{
							width: "160px",
							height: "160px",
							margin: "50px auto 50px auto",
							overflow: "hidden",
							borderRadius: "10px",

							backgroundImage: "url(images/logo-transparant.png)",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							opacity: "100%",
							position: "relative",
							zIndex: "-1",
						}}
					></Box>
					<h2 className='home_heading-names'>
						<span className='home_heading-names-iris'>Iris</span>
						<span className='home_heading-names-and'>&</span>
						<span className='home_heading-names-robin'>Robin</span>
					</h2>
					<h3 className='home_heading-date'>
						02 <span>.</span> 09 <span>.</span> 23
					</h3>
					<h3 className='home_heading-location'>Landgoed de Salentein</h3>
					<h3 className='home_heading-time'>
						{userData && userData.dayGuest ? "14:30" : "20:30"}
					</h3>
					<h4 className='home_heading-h4'>
						{language === "dutch"
							? "Welkom op onze trouwsite!"
							: "Welcome to our wedding website!"}
					</h4>
					{language === "dutch" ? (
						<p className='home_paragraph'>
							Via deze pagina kun je ons laten weten of je bij onze bruiloft
							bent. Via het menu aan de linkerkant kun je daarnaast alle
							praktische informatie over onze bruiloft vinden.
						</p>
					) : (
						<p className='home_paragraph'>
							On this page you can let us know if you will be attending our
							wedding. Via the menu on the left, you can also find all practical
							information for the day.
						</p>
					)}
					{language === "dutch" ? (
						<p className='home_paragraph'>
							Heb je vragen, wil je iets overleggen of doorgeven? Neem dan
							gerust contact op met onze ceremoniemeesters!
						</p>
					) : (
						<p className='home_paragraph'>
							Do you have questions, or is there anything you’d like to discuss?
							Please feel free to contact our masters of ceremony!
						</p>
					)}
					{language === "dutch" ? (
						<p className='home_paragraph'>
							Sandra en Rick zijn bereikbaar via het e-mailadres
							<b>
								<a
									href='mailto:ceremoniemeesters@irisenrobin.nl'
									className='home_link'
								>
									ceremoniemeesters@irisenrobin.nl
								</a>
							</b>
						</p>
					) : (
						<p className='home_paragraph'>
							You can reach Sandra and Rick via this email address:
							<p>
								<b>
									<a
										href='mailto:ceremoniemeesters@irisenrobin.nl'
										className='home_link'
									>
										ceremoniemeesters@irisenrobin.nl
									</a>
								</b>
							</p>
						</p>
					)}
					{userData && !userData.formFilled && (
						<Box
							sx={{
								padding: {
									xs: "30px 0px 0px 0px",
									sm: "30px 0px 0px 0px",
								},
							}}
						>
							<button
								className='landing-button'
								onClick={() => navigate("/rsvp")}
							>
								{language === "dutch" ? "IK BEN ERBIJ!" : "I'LL BE THERE!"}
							</button>
						</Box>
					)}
				</div>
			</Box>

			<Box
				sx={{
					padding: {
						xs: "0px 35px 100px 35px",
						sm: "0px 70px 200px 70px",
					},
				}}
			></Box>
		</Layout>
	);
};
