import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {redirect, useNavigate} from "react-router-dom";
import useNotification from "../hooks/useNotification";
import {useAuth} from "../provider/AuthProvider";
import {
	FilledInput,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
	checkPasswordCriteria,
	hasLowerAndUpper,
	hasNumber,
	hasSpecChar,
	isBetween,
	isValidName,
	sanitizeName,
} from "../helpers/FormValidation";
import "./SignInSide.css";
import {
	GreenFilledInput,
	GreenFormControl,
	GreenTextField,
	StyledButton,
} from "./ThemedComponents";
import {useLanguage} from "../provider/LanguageProvider";

const theme = createTheme();

export default function SignInSide() {
	const {login} = useAuth();
	const {language} = useLanguage();
	const navigate = useNavigate();
	const {addNotification, removeNotification} = useNotification();
	const [name, setName] = React.useState("");
	const [password, setPassword] = React.useState("");

	const [showPassword, setShowPassword] = React.useState(false);
	const [validationActivated, setValidationActivated] = React.useState(false);
	const [formReadyForSubmit, setFormReadyForSubmit] = React.useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setValidationActivated(true);
		let sanitizedName = sanitizeName(name);
		if (formReadyForSubmit) {
			try {
				await login(sanitizedName, password);
			} catch (error) {
				if (error.code === "auth/missing-email") {
					addNotification("U heeft geen e-mailadres ingevuld", "error");
				} else if (error.code === "auth/user-not-found") {
					addNotification("Dit e-mail adres kon niet worden gevonden", "error");
				} else if (error.code === "auth/wrong-password") {
					addNotification("U heeft een fout wachtwoord opgegeven", "error");
					setPassword("");
				} else {
					console.log(error);
					addNotification("Oeps, er is een fout opgetreden.", "error");
				}
				return;
			}
			addNotification("Successvol ingelogd", "success");
			navigate("/home");
		} else {
			addNotification("U moet het formulier juist invullen", "info");
		}
	};

	const checkFormReady = React.useCallback(() => {
		if (isBetween(password.length, 3, 25)) {
			if (isValidName(name)) {
				setFormReadyForSubmit(true);
				return true;
			}
		}
		setFormReadyForSubmit(false);
		return false;
	}, [password, name]);

	React.useEffect(() => {
		checkFormReady();
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				component='main'
				sx={{height: "100vh", overflow: "hidden"}}
			>
				<Grid
					item
					xs={12}
					sm={4}
					md={7}
					lg={8}
					sx={{
						minHeight: {xs: "30vh", sm: "100vh"},
						height: {sm: "100vh"},

						backgroundColor: "#fffaeb",
					}}
				>
					<Box
						sx={{
							backgroundImage: {
								xs: "url(images/landingpage0.5.jpg)",

								sm: "url(images/landingpage.jpg)",
							},
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: {
								sm: "40%",
								md: "center",
							},
							position: {
								xs: "relative",
								sm: "fixed",
							},
							width: {
								xs: "100vw",
								sm: "32vw",
								md: "57vw",
								lg: "66vw",
							},
							height: {
								xs: "30vh",
								sm: "100vh",
								md: "100vh",
								lg: "100vh",
							},
						}}
						id='imagebox'
					></Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					lg={4}
					sx={{
						width: {
							xs: "100vw",
							sm: "67vw",
							md: "42vw",
							lg: "33vw",
						},

						backgroundColor: "#fffaeb",
						zIndex: 10,
					}}
					id='gridright'
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Avatar sx={{m: 1, bgcolor: "#5d5f20"}}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component='h1' variant='h5'></Typography>
						<Box
							component='form'
							noValidate
							onSubmit={handleSubmit}
							sx={{mt: 1}}
						>
							<GreenTextField
								error={validationActivated && !isValidName(name)}
								margin='normal'
								fullWidth
								id='email'
								label={language === "dutch" ? "Naam" : "Name"}
								name='email'
								autoFocus
								variant='filled'
								value={name}
								onChange={(event) => setName(event.target.value)}
							/>

							<GreenFormControl variant='filled' fullWidth>
								<InputLabel
									htmlFor='filled-adornment-password'
									error={
										validationActivated && !isBetween(password.length, 3, 25)
									}
								>
									{language === "dutch" ? "Wachtwoord" : "Password"}
								</InputLabel>
								<FilledInput
									id='filled-adornment-password'
									type={showPassword ? "text" : "password"}
									fullWidth
									error={
										validationActivated && !isBetween(password.length, 3, 25)
									}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setShowPassword(!showPassword)}
												edge='end'
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									onChange={(event) => setPassword(event.target.value)}
									value={password}
								/>
							</GreenFormControl>

							<StyledButton
								type='submit'
								fullWidth
								variant='contained'
								sx={{
									mt: 3,
									mb: 2,
								}}
							>
								Log in
							</StyledButton>
							<Grid container>
								<Grid item xs>
									<Link
										href='/register'
										variant='body2'
										sx={{color: "#6e702d"}}
									>
										{language === "dutch"
											? "Nog geen account aangemaakt?"
											: "Don't have an account yet?"}
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
