import React, {useContext, useState} from "react";

export const LanguageContext = React.createContext({
	language: "dutch",
});

export function useLanguage() {
	return useContext(LanguageContext);
}

export default function LanguageProvider({children}) {
	const [language, setLanguage] = useState("dutch");

	const switchLanguage = () => {
		if (language === "dutch") {
			setLanguage("english");
		} else {
			setLanguage("dutch");
		}
	};

	const contextValue = {
		language,
		switchLanguage,
	};

	return (
		<LanguageContext.Provider value={contextValue}>
			{children}
		</LanguageContext.Provider>
	);
}
