import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {db} from "../../helpers/FirebaseInit";
import {collection, query, where, getDocs, addDoc} from "firebase/firestore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import useNotification from "../../hooks/useNotification";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

import Loader from "../../components/Loader";
import AdminMenu from "../../components/AdminMenu.js/AdminMenu";

export const AdminAddGuestScreen = () => {
	const {addNotification} = useNotification();
	const [familyName, setName] = useState("");
	const [guestName1, setGuestName1] = useState("");
	const [guestName2, setGuestName2] = useState("");
	const [guestName3, setGuestName3] = useState("");
	const [guestName4, setGuestName4] = useState("");
	const [guestName5, setGuestName5] = useState("");
	const [guestName6, setGuestName6] = useState("");
	const [guestName7, setGuestName7] = useState("");
	const [guestName8, setGuestName8] = useState("");
	const [guestName9, setGuestName9] = useState("");
	const [iterable, setIterable] = useState([1]);
	const [loading, setLoading] = useState(true);
	const [guestlist, setGuestlist] = useState([]);
	const [numberGuests, setNumberGuests] = useState(1);
	const [dayGuest, setDayGuest] = useState(true);

	const userSuccesfullyCreatedFunction = () => {
		addNotification("Nieuwe gast geregistreerd.", "info");
		setName("");
		setLoading(true);
		setGuestName1("");
		setGuestName2("");
		setGuestName3("");
		setGuestName4("");
		setGuestName5("");
		setGuestName6("");
		setGuestName7("");
		setGuestName8("");
		setGuestName9("");
		setNumberGuests(1);
	};

	const setIterableFunc = (num) => {
		const tmpArray = [];
		for (let i = 0; i < num; i++) {
			tmpArray.push(1);
		}
		setIterable(tmpArray);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const guestNames = [];
		let i = 1;
		for await (let item of iterable) {
			guestNames.push({
				idNum: i,
				name: eval(`guestName${i}`),
				presence: "",
				song: "",
				allergieInfo: "",
			});
			i++;
		}
		try {
			const docRef = await addDoc(collection(db, "guests"), {
				familyName,
				otpUsed: false,
				otp: Math.floor(100000 + Math.random() * 900000).toString(),
				dayGuest,
				guestNames,
				hasAllergie: false,
			});
			console.log("Document written with ID: ", docRef.id);
		} catch (e) {
			console.log("error while adding new guest", e);
		}

		userSuccesfullyCreatedFunction();
	};

	const handleEnter = (event) => {
		if (event.key === "Enter") {
			handleSubmit(event);
		}
	};

	const fetchGuestList = async () => {
		const q = query(collection(db, "guests"));

		const newGuestlist = [];

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			newGuestlist.push(doc.data());
		});

		setGuestlist(newGuestlist);

		setTimeout(() => {
			setLoading(false);
			const input = document.querySelector("#naam-input");
			input.focus();
		}, 500);
	};

	useEffect(() => {
		fetchGuestList();
	}, [loading]);

	return (
		<>
			<Box
				component='form'
				sx={{
					maxWidth: 500,
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					margin: "100px",
					marginRight: "auto",
					marginLeft: "auto",
					padding: "10px",
				}}
				noValidate
				autoComplete='off'
				onSubmit={handleSubmit}
			>
				<Typography
					variant='formtitle'
					sx={{
						marginBottom: "20px",
						fontSize: "18px",
						color: "darkgreen",
					}}
				>
					Nieuwe gast toevoegen
				</Typography>

				<Box sx={{width: "100%", marginBottom: "20px"}}>
					<Typography
						variant='body'
						sx={{
							display: "block",
							marginBottom: "10px",
							fontSize: "14px",
							color: "darkgreen",
							width: "100%",
							textAlign: "left",
						}}
					>
						Naam familie / gastengroep
					</Typography>
					<TextField
						id='naam-input'
						label='Naam'
						value={familyName}
						onChange={(event) => {
							setName(event.target.value);
						}}
						sx={{width: "100%"}}
					/>
					<FormControl
						sx={{
							marginTop: "30px",

							minWidth: 120,
							width: "100%",
						}}
					>
						<InputLabel id='simple-select-label'>Dag of avond gast</InputLabel>
						<Select
							labelId='simple-select-standard-label'
							id='simple-select'
							value={dayGuest}
							onChange={(e) => {
								setDayGuest(e.target.value);
							}}
							label='Dag of avond gast'
						>
							<MenuItem value={true}>Dag</MenuItem>
							<MenuItem value={false}>Avond</MenuItem>
						</Select>
					</FormControl>
					<FormControl
						// variant='standard'
						sx={{
							marginTop: "30px",
							marginBottom: "40px",
							minWidth: 120,
							width: "100%",
						}}
					>
						<InputLabel id='simple-select-label'>Aantal personen</InputLabel>
						<Select
							labelId='simple-select-standard-label'
							id='simple-select'
							value={numberGuests}
							onChange={(e) => {
								setNumberGuests(e.target.value);
								setIterableFunc(e.target.value);
							}}
							label='Aantal personen'
						>
							<MenuItem value={"1"}>1</MenuItem>
							<MenuItem value={"2"}>2</MenuItem>
							<MenuItem value={"3"}>3</MenuItem>
							<MenuItem value={"4"}>4</MenuItem>
							<MenuItem value={"5"}>5</MenuItem>
							<MenuItem value={"6"}>6</MenuItem>
							<MenuItem value={"7"}>7</MenuItem>
							<MenuItem value={"8"}>8</MenuItem>
							<MenuItem value={"9"}>9</MenuItem>
						</Select>
					</FormControl>
					<Typography
						variant='formtitle'
						sx={{
							marginBottom: "20px",

							fontSize: "18px",
							color: "darkgreen",
						}}
					>
						Gastennamen
					</Typography>
					<Typography
						variant='body'
						sx={{
							display: "block",
							marginBottom: "10px",
							fontSize: "14px",
							color: "darkgreen",
							width: "100%",
							textAlign: "left",
						}}
					>
						Naam gast 1
					</Typography>
					<TextField
						id='outlined-controlled'
						label='Naam'
						value={guestName1}
						onChange={(event) => {
							setGuestName1(event.target.value);
						}}
						sx={{width: "100%"}}
						onKeyUp={handleEnter}
					/>

					{numberGuests > 1 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 2
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName2}
								onChange={(event) => {
									setGuestName2(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 2 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 3
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName3}
								onChange={(event) => {
									setGuestName3(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 3 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 4
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName4}
								onChange={(event) => {
									setGuestName4(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 4 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 5
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName5}
								onChange={(event) => {
									setGuestName5(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 5 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 6
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName6}
								onChange={(event) => {
									setGuestName6(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 6 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 7
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName7}
								onChange={(event) => {
									setGuestName7(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 7 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 8
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName8}
								onChange={(event) => {
									setGuestName8(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
					{numberGuests > 8 && (
						<Box sx={{margin: "20px 0px"}}>
							<Typography
								variant='body'
								sx={{
									display: "block",
									marginBottom: "10px",
									fontSize: "14px",
									color: "darkgreen",
									width: "100%",
									textAlign: "left",
								}}
							>
								Naam gast 2
							</Typography>
							<TextField
								id='outlined-controlled'
								label='Naam'
								value={guestName9}
								onChange={(event) => {
									setGuestName9(event.target.value);
								}}
								sx={{width: "100%"}}
								onKeyUp={handleEnter}
							/>
						</Box>
					)}
				</Box>
				<Box>
					<Button onClick={(e) => handleSubmit(e)}>Voeg toe</Button>
				</Box>
			</Box>

			<Box
				component='form'
				sx={{
					maxWidth: 500,
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					margin: "10px",
					marginRight: "auto",
					marginLeft: "auto",
					padding: "10px",
				}}
				noValidate
				autoComplete='off'
			>
				<Typography
					variant='formtitle'
					sx={{
						marginBottom: "20px",
						fontSize: "18px",
						color: "darkgreen",
					}}
				>
					Gastenlijst
				</Typography>
				<Box>
					{loading && <Loader />}
					{!loading && guestlist.length === 0 && <Box>Geen gasten</Box>}
					{!loading && (
						<Box sx={{marginLeft: "10px", marginRight: "10px"}}>
							<List>
								{guestlist.map((guest) => (
									<ListItem
										key={guest.otp}
										sx={{
											borderBottom: "solid 1px rgb(0,0,0,0.15)",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<span>{guest.familyName}</span>
										<span>otp: {guest.otp}</span>
									</ListItem>
								))}
							</List>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
