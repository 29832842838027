import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useAuth} from "../../provider/AuthProvider";
import {useEffect, useState} from "react";
import axios from "axios";
import {db} from "../../helpers/FirebaseInit";
import {
	collection,
	query,
	where,
	getDocs,
	addDoc,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import useNotification from "../../hooks/useNotification";
import {auth} from "../../helpers/FirebaseInit";
import {
	Avatar,
	Button,
	Divider,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import NoFoodIcon from "@mui/icons-material/NoFood";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

import {doc, setDoc} from "firebase/firestore";
import Loader from "../../components/Loader";
import {useNavigate, useParams} from "react-router-dom";

export const AdminGuestDetailsScreen = () => {
	const {signup} = useAuth();
	const navigate = useNavigate();

	const {addNotification} = useNotification();
	const [name = "", setName] = useState();
	const [loading, setLoading] = useState(true);
	const [guestNames, setGuestNames] = useState([]);
	const [userData, setUserData] = useState([]);
	const [newUsers, setNewUsers] = useState([]);
	const {guestId} = useParams();

	const userSuccesfullyCreatedFunction = () => {
		addNotification("Nieuwe gast geregistreerd.", "info");
		setName("");
		setLoading(true);
	};

	const fetchUserData = async () => {
		const docRef = doc(db, "guests", guestId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setUserData(docSnap.data());
			setGuestNames(docSnap.data().guestNames);
			setLoading(false);
		} else {
			console.log("No such document!");
			setLoading(false);
		}
	};

	const changeOTP = async () => {
		const docRef = doc(db, "guests", guestId);

		await updateDoc(docRef, {
			otpUsed: !userData.otpUsed,
		});
		setLoading(true);
	};
	const changeDayGuest = async () => {
		const docRef = doc(db, "guests", guestId);

		await updateDoc(docRef, {
			dayGuest: !userData.dayGuest,
		});
		setLoading(true);
	};

	useEffect(() => {
		fetchUserData();
	}, [loading]);

	return (
		<>
			<Box
				sx={{
					maxWidth: 600,
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					margin: "10px",
					marginRight: "auto",
					marginLeft: "auto",
					padding: "20px",
					border: "2px solid rgb(0,0,0,0.1)",
					borderRadius: "20px",
				}}
				noValidate
				autoComplete='off'
			>
				<Typography
					variant='formtitle'
					sx={{
						marginBottom: "20px",
						fontSize: "22px",
						color: "darkgreen",
					}}
				>
					Gastdetails
				</Typography>
				<Box sx={{width: "100%", textAlign: "left"}}>
					{loading && <Loader />}
					{!loading && (
						<>
							<Box sx={{marginLeft: "10px", marginRight: "10px"}}>
								<h3>Naam</h3>
								<div>{userData.familyName}</div>
								<h3>Formulier ingevuld:</h3>
								<div>{userData.formFilled ? "yes" : "no"}</div>
								<h3>OTP gebruikt:</h3>
								<div>{userData.otpUsed ? "yes" : "no"}</div>
								<div>OTP code: {userData.otp}</div>
								<Button
									variant='contained'
									onClick={() => changeOTP()}
									sx={{
										backgroundColor: "#2a5b18",
										"&:hover": {
											backgroundColor: "#437431",
										},
									}}
								>
									Zet om
								</Button>
								<h3>Dag gast:</h3>
								<div>{userData.dayGuest ? "ja" : "nee"}</div>
								<Button
									variant='contained'
									onClick={() => changeDayGuest()}
									sx={{
										backgroundColor: "#2a5b18",
										"&:hover": {
											backgroundColor: "#437431",
										},
									}}
								>
									Zet om
								</Button>
								<h3>Email:</h3>
								<div>{userData.email}</div>
								<Typography
									variant='h2'
									sx={{
										marginTop: "40px",
										marginBottom: "20px",
										fontSize: "18px",
										color: "darkgreen",
									}}
								>
									Namen en formulier info
								</Typography>
								{!loading &&
									guestNames &&
									// guestNames.length > 1 &&
									userData.formFilled &&
									guestNames.map((guest, index) => (
										<Box
											sx={{
												"&:not(:last-child)": {
													marginBottom: "50px",
												},
												"&:last-child": {
													marginBottom: "30px",
												},
											}}
											key={guest.idNum}
										>
											<Typography variant='h4'>{guest.name}</Typography>

											<Box
												sx={{
													width: "400px",
													borderRadius: "10px",
													border: "1px solid rgb(0,0,0,0.1)",
													marginTop: "20px",
													marginLeft: "20px",
													boxShadow: "5px 5px 15px RGB(0,0,0,0.15)",
												}}
											>
												<List
													sx={{
														width: "100%",
														maxWidth: 360,
														bgcolor: "background.paper",
														borderRadius: "10px",
													}}
												>
													<ListItem>
														<ListItemAvatar>
															<Avatar sx={{backgroundColor: "#2a5b18"}}>
																<AccountCircleIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary='Aanwezig'
															secondary={guest.presence ? "Ja" : "Nee"}
														/>
													</ListItem>
													<Divider variant='inset' component='li' />

													<ListItem>
														<ListItemAvatar>
															<Avatar sx={{backgroundColor: "#2a5b18"}}>
																<AccountCircleIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary='Gebruikersnaam'
															secondary={guest.name}
														/>
													</ListItem>
													<Divider variant='inset' component='li' />

													<ListItem>
														<ListItemAvatar>
															<Avatar sx={{backgroundColor: "#2a5b18"}}>
																<NoFoodIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary='Dieet wensen'
															secondary={
																guest.allergies
																	? guest.allergieInfo
																	: "Niet opgegeven."
															}
														/>
													</ListItem>
													<Divider variant='inset' component='li' />

													<ListItem>
														<ListItemAvatar>
															<Avatar sx={{backgroundColor: "#2a5b18"}}>
																<MusicNoteIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary='Wilt ons meegeven'
															secondary={guest.song}
														/>
													</ListItem>
												</List>
											</Box>
										</Box>
									))}

								<Box sx={{display: "flex", justifyContent: "center"}}>
									<Button
										variant='contained'
										onClick={() => navigate(-1)}
										sx={{
											backgroundColor: "#2a5b18",
											"&:hover": {
												backgroundColor: "#437431",
											},
										}}
									>
										Terug
									</Button>
									<Button
										variant='text'
										sx={{color: "#2a5b18", marginLeft: "10px"}}
										onClick={() => {}}
									>
										Wijzig
									</Button>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
