import {CheckCircleOutline, InfoOutlined} from "@mui/icons-material";
import {Alert, AlertTitle} from "@mui/material";
import {Box} from "@mui/system";
import {useState} from "react";
import useError from "../hooks/useNotification";

export const ErrorAlert = ({text}) => {
	const {error} = useError();

	return (
		<>
			{error && error.message && (
				<Box
					sx={{
						position: "absolute",
						// width: '100%',
						left: "50%",
						transform: "translateX(-45%)",
						zIndex: "9999",
						marginTop: "15px",
					}}
				>
					<Box
						sx={{
							width: 300,
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Alert
							icon={
								(error.type === "error" && (
									<InfoOutlined fontSize='inherit'></InfoOutlined>
								)) ||
								(error.type === "success" && (
									<CheckCircleOutline fontSize='inherit'></CheckCircleOutline>
								)) ||
								(error.type === "info" && (
									<InfoOutlined fontSize='inherit'></InfoOutlined>
								))
							}
							severity={
								(error.type === "error" && "error") ||
								(error.type === "info" && "info") ||
								(error.type === "success" && "success")
							}
						>
							{error.message}
						</Alert>
					</Box>
				</Box>
			)}
		</>
	);
};
