import React, { useState, useCallback } from 'react';

export const NotificationContext = React.createContext({
	error: null,
	addNotification: () => {},
	removeNotification: () => {},
});

export default function NotificationProvider({ children }) {
	const [error, setNotification] = useState(null);
	const removeNotification = () => setNotification(null);
	const addNotification = (message, type) => {
		setNotification({ message, type });
		setTimeout(() => {
			removeNotification();
		}, 4000);
	};
	const contextValue = {
		error,
		addNotification: useCallback((message, type) => addNotification(message, type), []),
		removeNotification: useCallback(() => removeNotification(), []),
	};

	return (
		<NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
	);
}
