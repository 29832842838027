import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useAuth} from "../../provider/AuthProvider";
import {useEffect, useState} from "react";
import axios from "axios";
import {db} from "../../helpers/FirebaseInit";
import {
	collection,
	query,
	where,
	getDocs,
	addDoc,
	orderBy,
	limit,
	getCountFromServer,
	startAt,
	startAfter,
} from "firebase/firestore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import useNotification from "../../hooks/useNotification";
import {auth} from "../../helpers/FirebaseInit";
import {
	Button,
	Divider,
	Link,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import {doc, setDoc} from "firebase/firestore";
import Loader from "../../components/Loader";
import AdminMenu from "../../components/AdminMenu.js/AdminMenu";
import {printExcel, printPostageExcel} from "../../helpers/PrintExcel";
import {useNavigate} from "react-router-dom";

export const AdminGuestListScreen = () => {
	const {signup} = useAuth();
	const navigate = useNavigate();

	const {addNotification} = useNotification();
	const [name = "", setName] = useState();
	const [loading, setLoading] = useState(true);
	const [guestlist, setGuestlist] = useState([]);
	const [totalPresence, setTotalPresence] = useState(0);
	const [page, setPage] = useState(1);
	const [numPages, setNumPages] = useState(1);

	const [pageData, setPageData] = useState([]);

	const userSuccesfullyCreatedFunction = () => {
		addNotification("Nieuwe gast geregistreerd.", "info");
		setName("");
		setLoading(true);
	};

	const fetchGuestList = async () => {
		const snapshot = await getCountFromServer(query(collection(db, "guests")));
		const numEntries = snapshot.data().count;

		let q;
		let entriesPerPage = 25;
		let documentSnapshots;
		let newArray = [""];

		if (numEntries > entriesPerPage) {
			let numberPages = Math.ceil(numEntries / entriesPerPage);
			setNumPages(numberPages);
			let currentPage = 1;
			q = query(
				collection(db, "guests"),
				orderBy("familyName"),
				limit(entriesPerPage)
			);
			documentSnapshots = await getDocs(q);
			let lastVisible =
				documentSnapshots.docs[documentSnapshots.docs.length - 1];
			newArray.push(documentSnapshots.docs);
			while (currentPage <= numberPages) {
				q = query(
					collection(db, "guests"),
					orderBy("familyName"),
					limit(entriesPerPage),
					startAfter(lastVisible)
				);
				documentSnapshots = await getDocs(q);
				lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
				newArray.push(documentSnapshots.docs);
				currentPage++;
			}
			setPageData(newArray);
		} else {
			q = query(
				collection(db, "guests"),
				orderBy("familyName"),
				limit(entriesPerPage)
			);
			documentSnapshots = await getDocs(q);
			newArray.push(documentSnapshots.docs);
			setPageData(newArray);
		}

		setLoading(false);
	};

	const preparePrint = () => {
		const printableGuestlist = [];
		const data = [...pageData];
		data.shift();
		data.map((page) => {
			page.map((doc) => {
				const docData = doc.data();
				docData.id = doc.id;
				printableGuestlist.push(docData);
			});
		});
		console.log(printableGuestlist);
		return printableGuestlist;
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const renderPresence = (guest) => {
		let presence = false;
		let counter = 0;
		let total = 0;
		let state;
		guest.guestNames.forEach((guestName) => {
			if (guestName.presence === "yes") {
				presence = true;
				counter++;
			}
			total++;
		});
		return (
			<div>
				{presence ? `Ja ${counter}/${total}` : `Nee ${counter}/${total}`}
			</div>
		);
	};

	const renderTotalPresence = (pagedata) => {
		let newArray = [...pagedata];
		newArray.shift();
		if (numPages > 1) {
			newArray.pop();
		}
		console.log(newArray);
		let total = 0;
		newArray.map((page) => {
			page.forEach((guest) => {
				guest.data().guestNames.forEach((name) => {
					if (name.presence === "yes") {
						total++;
					}
				});
			});
		});
		return <span>{total}</span>;
	};

	useEffect(() => {
		fetchGuestList();
	}, []);

	return (
		<>
			<AdminMenu />
			<Box
				component='form'
				sx={{
					maxWidth: 900,
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					margin: "10px",
					marginRight: "auto",
					marginLeft: "auto",
					padding: "10px",
					border: "2px solid rgb(0,0,0,0.1)",
					borderRadius: "20px",
				}}
				noValidate
				autoComplete='off'
			>
				<Typography
					variant='formtitle'
					sx={{
						marginBottom: "20px",
						fontSize: "18px",
						color: "darkgreen",
					}}
				>
					Gastenlijst
				</Typography>
				<Box>
					{loading && <Loader />}
					{!loading && pageData.length === 0 && <Box>Geen gasten</Box>}
					{!loading && (
						<Box sx={{marginLeft: "10px", marginRight: "10px"}}>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>
												<span>Naam</span>
											</TableCell>
											<TableCell>
												<span>Account gemaakt</span>
											</TableCell>
											<TableCell>
												<span>Formulier ingevuld</span>
											</TableCell>
											<TableCell>
												<span>Aanwezig</span>
											</TableCell>
											<TableCell>
												<span>Allergieën?</span>
											</TableCell>
											<TableCell>
												<span></span>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pageData[page].map((doc) => (
											<TableRow key={doc.id}>
												<TableCell>{doc.data().familyName}</TableCell>
												<TableCell>
													{doc.data().otpUsed ? "Ja" : "Nee"}
												</TableCell>
												<TableCell>
													{doc.data().formFilled ? "Ja" : "Nee"}
												</TableCell>
												<TableCell>{renderPresence(doc.data())}</TableCell>
												<TableCell>
													{doc.data().formFilled &&
														(doc.data().hasAllergie ? "JA" : "Nee")}
												</TableCell>
												<TableCell>
													<Box
														sx={{cursor: "pointer"}}
														onClick={() => navigate(`/admin/guest/${doc.id}`)}
													>
														Details
													</Box>
												</TableCell>
											</TableRow>
										))}

										<TableRow key='bottom'>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell sx={{whiteSpace: "no-wrap"}}>
												Totaal: {renderTotalPresence(pageData)}
											</TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					)}
					{numPages > 1 && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								margin: "5px 0px",
							}}
						>
							<Pagination
								count={numPages}
								shape='rounded'
								page={page}
								onChange={handlePageChange}
							/>
						</Box>
					)}
					<Button onClick={() => printExcel(preparePrint())}>
						Download Excel
					</Button>
					<Button onClick={() => printPostageExcel(preparePrint())}>
						Postage excel
					</Button>
				</Box>
			</Box>
		</>
	);
};
