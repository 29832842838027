import {Box} from "@mui/material";
import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";

export const ContactScreen = () => {
	const {language} = useLanguage();
	return (
		<Layout>
			<div className='contact_header-container'>
				<div className='contact_header-background'></div>
				<h2 className='contact_header'>Contact</h2>
			</div>

			<Box
				sx={{
					padding: {
						xs: "0px 20px 100px 20px",
						sm: "200px 30px 0px 30px",
					},
				}}
			>
				<Box
					sx={{
						maxWidth: "100%",
						height: "300px",
						margin: "40px 0px 35px 0px",
						overflow: "hidden",
						borderRadius: "10px",

						backgroundImage:
							"url(https://images.unsplash.com/photo-1580337943677-d8fc56f89ecb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						opacity: "75%",
						position: "relative",
						zIndex: "-1",
					}}
				></Box>

				{language === "dutch" ? (
					<p className='contact_paragraph'>
						Heb je vragen, wil je iets overleggen of doorgeven? Neem dan gerust
						contact op met onze ceremoniemeesters! Sandra en Rick zijn
						bereikbaar via het e-mailadres{" "}
						<Box
							sx={{
								marginTop: "30px",
							}}
						>
							<b>
								<a href='mailto:ceremoniemeesters@irisenrobin.nl'>
									ceremoniemeesters@irisenrobin.nl
								</a>
							</b>
						</Box>
					</p>
				) : (
					<>
						<p className='contact_paragraph'>
							Do you have questions, or is there anything you’d like to discuss?
							Please feel free to get in touch with our masters of ceremony!
						</p>
						<p className='contact_paragraph'>
							You can reach Sandra and Rick via this email address:
							<Box
								sx={{
									marginTop: "30px",
								}}
							>
								<b>
									<a href='mailto:ceremoniemeesters@irisenrobin.nl'>
										ceremoniemeesters@irisenrobin.nl
									</a>
								</b>
							</Box>
						</p>
					</>
				)}
			</Box>
		</Layout>
	);
};
