import {getAuth} from "firebase/auth";
import {Navigate} from "react-router-dom";
import useNotification from "../hooks/useNotification";
import {useAuth} from "../provider/AuthProvider";
import {firebase} from "./FirebaseInit";

export const AdminRoute = ({children}) => {
	const {addNotification} = useNotification();
	const {currentUser, isAdmin, loading} = useAuth();
	if (!currentUser) {
		return <Navigate to='/admin/login' replace />;
	}
	if (!isAdmin) {
		setTimeout(() => {
			addNotification(
				"U heeft niet de juiste rechten voor deze pagina.",
				"error"
			);
		}, 50);
		return <Navigate to='/account' replace />;
	}
	return children;
};
