import "./MapBox.css";
import mapboxgl, {NavigationControl} from "mapbox-gl";
import React, {useEffect, useRef, useState} from "react";
mapboxgl.accessToken =
	"pk.eyJ1Ijoicm9iaW5tb250c3Vwcm8iLCJhIjoiY2xoZzF4Y2V0MDAwczNucWphY2s0M3JlbCJ9.KN4AaZjST2gxeW0jA0Ezcg";

export default function MapBox() {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState(5.1942);
	const [lat, setLat] = useState(52.2572);
	const [zoom, setZoom] = useState(7.9);

	// lat 5,496864, lng 52,235676
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/robinmontsupro/clhg29qv9019a01qu3zprh1o5",
			center: [lng, lat],
			zoom: zoom,
		});
		if (!map.current) return;
		map.current.on("move", () => {
			setLng(map.current.getCenter().lng.toFixed(4));
			setLat(map.current.getCenter().lat.toFixed(4));
			setZoom(map.current.getZoom().toFixed(2));
		});

		const popup = new mapboxgl.Popup({
			offset: [0, -15],
			closeOnClick: false,
			closeOnMove: false,
			closeButton: false,
		})
			.setLngLat({lng: 5.496864, lat: 52.235676})
			.setHTML(
				`<span class="mapbox-popup-header">Landgoed de Salentein</span><p>Putterstraatweg 5–9</p><p> 3862 RA Nijkerk</p><a href="https://goo.gl/maps/4WWfLMoat8c4uVHT8" target="blank">Googlemaps</a></p>`
			)
			.addTo(map.current);
	});

	return (
		<div>
			<div ref={mapContainer} className='map-container' />
		</div>
	);
}
