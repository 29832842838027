import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";

export const Layout = ({children}) => {
	return (
		<Grid container component='main'>
			<Grid
				item
				xs={12}
				sm={4}
				md={7}
				lg={8}
				sx={{
					minHeight: {xs: "30vh", sm: "100vh"},
					height: {sm: "100vh"},

					backgroundColor: "#fffaeb",
				}}
			>
				<Box
					sx={{
						backgroundImage: {
							xs: "url(/images/landingpage0.5.jpg)",

							sm: "url(/images/landingpage.jpg)",
						},
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: {
							sm: "40%",
							md: "center",
						},
						position: {
							xs: "relative",
							sm: "fixed",
						},
						width: {
							xs: "100vw",
							sm: "32vw",
							md: "57vw",
							lg: "66vw",
						},
						height: {
							xs: "30vh",
							sm: "100vh",
							md: "100vh",
							lg: "100vh",
						},
					}}
					id='imagebox'
				></Box>
			</Grid>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				lg={4}
				sx={{
					width: {
						xs: "100vw",
						sm: "67vw",
						md: "42vw",
						lg: "33vw",
					},

					backgroundColor: "#fffaeb",
					zIndex: 10,
				}}
				id='gridright'
			>
				{children}
			</Grid>
		</Grid>
	);
};
