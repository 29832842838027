import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {redirect, useNavigate} from "react-router-dom";
import useNotification from "../../hooks/useNotification";
import {useAuth} from "../../provider/AuthProvider";
import {
	FilledInput,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const theme = createTheme();

export default function AdminLogin() {
	const {loginAdmin} = useAuth();
	const navigate = useNavigate();
	const {addNotification, removeNotification} = useNotification();
	const [name, setName] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [validationActivated, setValidationActivated] = React.useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setValidationActivated(true);
		if (!isValid) {
			addNotification("U heeft geen naam ingevoerd.", "error");
			return;
		}
		try {
			await loginAdmin(name, password);
		} catch (error) {
			if (error.code === "auth/missing-email") {
				addNotification("U heeft geen e-mailadres ingevuld", "error");
			} else if (error.code === "auth/user-not-found") {
				addNotification("Dit e-mail adres kon niet worden gevonden", "error");
			} else {
				addNotification("Oeps, er is een fout opgetreden.", "error");
			}
			return;
		}
		addNotification("Successvol ingelogd", "success");
		navigate("/admin/main");
	};

	const isValid = () => {
		if (name === "") {
			return false;
		} else {
			return true;
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid container component='main' sx={{height: "100vh"}}>
				<CssBaseline />

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					component={Paper}
					elevation={6}
					square
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component='h1' variant='h5'></Typography>
						<Box
							component='form'
							noValidate
							onSubmit={handleSubmit}
							sx={{mt: 1}}
						>
							<TextField
								margin='normal'
								fullWidth
								id='email'
								label='Email'
								name='email'
								autoFocus
								variant='filled'
								value={name}
								onChange={(event) => setName(event.target.value)}
							/>
							<FormControl variant='filled' fullWidth>
								<InputLabel htmlFor='filled-adornment-password'>
									Wachtwoord
								</InputLabel>
								<FilledInput
									id='filled-adornment-password'
									type={showPassword ? "text" : "password"}
									fullWidth
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setShowPassword(!showPassword)}
												// onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									onChange={(event) => setPassword(event.target.value)}
									value={password}
								/>
							</FormControl>

							<Button
								type='submit'
								fullWidth
								variant='contained'
								sx={{mt: 3, mb: 2}}
							>
								Log in
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href='#' variant='body2'>
										Lukt het inloggen niet?
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
