// Form validation helper functions:
export const isEmpty = (value) => (value === "" ? true : false);
export const isBetween = (length, min, max) =>
	length < min || length > max ? false : true;
export const isValidEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};
export const isValidName = (name = "") => {
	if (isEmpty(name)) {
		return false;
	}
	if (!isBetween(name.length, 6, 25)) {
		return false;
	}
	if (hasNumber(name)) {
		return false;
	}
	if (mayBeEmail(name)) {
		return false;
	}
	return true;
};
export const hasLowerAndUpper = (password) => {
	const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
	return re.test(password);
};
export const hasNumber = (password) => {
	const re = new RegExp("^(?=.*[0-9])");
	return re.test(password);
};
export const hasSpecChar = (password) => {
	const re = new RegExp("^(?=.*[!@#$%^&*])");
	return re.test(password);
};
export const mayBeEmail = (password) => {
	const re = new RegExp("^(?=.*[@])");
	return re.test(password);
};

export const greaterThanZero = (number) => {
	return number > 0;
};

export const validOtp = (otp) => {
	if (isEmpty(otp)) {
		return false;
	}
	if (!isBetween(otp.length, 6, 6)) {
		return false;
	}
	const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
	if (re.test(otp)) {
		return false;
	}
	return true;
};

export const checkPasswordCriteria = (password, confirmPassword) => {
	if (
		isBetween(password.length, 3, 25) &&
		// hasLowerAndUpper(password) &&
		// hasNumber(password) &&
		// hasSpecChar(password) &&
		password === confirmPassword
	) {
		return true;
	}
	return false;
};

export const sanitizeName = (name) => {
	let sanitized = name.replace(/ /g, "_");
	return sanitized;
};
