import {Box} from "@mui/material";

import {Layout} from "../../components/Layout";
import {useAuth} from "../../provider/AuthProvider";
import {useLanguage} from "../../provider/LanguageProvider";

export const DresscodeScreen = () => {
	const {currentUser, userData} = useAuth();
	const {language} = useLanguage();

	return (
		<Layout>
			<div className='dresscode_header-container'>
				<div className='dresscode_header-background'></div>
				<h2 className='dresscode_header'>Dresscode</h2>
			</div>
			<Box
				sx={{
					padding: {
						xs: "0px 20px 100px 20px",
						sm: "200px 30px 100px 30px",
					},
				}}
				className='dresscode_body'
			>
				<Box
					sx={{
						maxWidth: "100%",
						height: "300px",
						margin: "40px 0px 25px 0px",
						overflow: "hidden",
						borderRadius: "10px",

						backgroundImage:
							"url(https://www.yeahweddings.com/wp-content/uploads/2021/04/wedding-guests.jpg)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						opacity: "90%",
						position: "relative",
						zIndex: "-1",
					}}
				></Box>

				{language === "dutch" ? (
					<>
						{userData && userData.dayGuest ? (
							<>
								<p className='dresscode_paragraph'>
									De dresscode voor onze bruiloft is:
								</p>
								<h3 className='text-emphasis'>Summer Chic</h3>
								<p className='dresscode_paragraph'>
									Kom gerust in de kleding die je fijn vindt om te dragen, maar
									weet dat wij een meer formele outfit zeker waarderen.
								</p>
								<p className='dresscode_paragraph'>
									Houd er rekening mee dat de ceremonie, de borrel en het diner
									buiten plaatsvinden. Zorg er dus voor dat je iets bij je hebt
									om aan te trekken als het frisser wordt. Het feest vindt
									binnen plaats.
								</p>
							</>
						) : (
							<>
								<p className='dresscode_paragraph'>
									De dresscode voor onze bruiloft is:
								</p>
								<h3 className='text-emphasis'>Summer Chic</h3>
								<p className='dresscode_paragraph'>
									Kom gerust in de kleding die je fijn vindt om te dragen, maar
									weet dat wij een meer formele outfit zeker waarderen.
								</p>
							</>
						)}
					</>
				) : (
					<>
						{userData && userData.dayGuest ? (
							<>
								<p className='dresscode_paragraph'>
									The dresscode for our wedding is:
								</p>
								<h3 className='text-emphasis'>Summer Chic</h3>
								<p className='dresscode_paragraph'>
									Please feel free to wear an outfit you feel comfortable in,
									but do know that we appreciate a slightly formal outfit.
								</p>
								<p className='dresscode_paragraph'>
									Keep in mind that the ceremony, toast and dinner will take
									place outdoors. Be prepared and bring something warm to wear
									in case it gets chilly. The party will take place indoors.
								</p>
							</>
						) : (
							<>
								<p className='dresscode_paragraph'>
									The dresscode for our wedding is:
								</p>
								<h3 className='text-emphasis'>Summer Chic</h3>
								<p className='dresscode_paragraph'>
									Please feel free to wear an outfit you feel comfortable in,
									but do know that we appreciate a slightly formal outfit.
								</p>
							</>
						)}
					</>
				)}
			</Box>
		</Layout>
	);
};
