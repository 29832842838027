// const fs = require("fs");
const excel = require("exceljs");

export const printExcel = async (guestList) => {
	console.log(guestList);
	let workbook = new excel.Workbook(); //creating workbook
	let worksheet = workbook.addWorksheet("Customers"); //creating worksheet

	//  WorkSheet Header
	worksheet.columns = [
		{header: "Invite Name", key: "name", width: 25},
		{header: "Gast Naam", key: "gastnaam", width: 25},
		{header: "Dag/Avond gast", key: "dayguest", width: 25},
		{header: "Account gemaakt", key: "account", width: 10},
		{header: "Formulier ingevuld", key: "form", width: 15},
		{header: "Aanwezig", key: "presence", width: 10},
		{header: "Hele avond?", key: "party", width: 30},
		{header: "Allergieën", key: "allergies", width: 30},
		{header: "Allergie info", key: "allergieInfo", width: 30},
		{header: "Bericht", key: "song", width: 30},
		{header: "Email", key: "email", width: 30},
	];

	const rows = [];
	for (let row of guestList) {
		console.log(row);
		for (let guest of row.guestNames) {
			let newObj = {};
			newObj.name = row.familyName;
			newObj.gastnaam = guest.name;
			newObj.dayguest = row.dayGuest ? "Daggast" : "Avondgast";
			newObj.form = row.formFilled ? "Ja" : "Niet ingevuld";
			newObj.account = row.otpUsed ? "Ja" : "Nee";
			newObj.presence =
				(guest.presence === "yes" && "Ja") ||
				(guest.presence === "no" && "Niet aanwezig") ||
				(guest.presence === "maybe" && "Misschien") ||
				(guest.presence === "" && "Niet ingevuld");
			newObj.song = guest.song;
			newObj.email = row.email ? row.email : "Niet ingevuld";
			newObj.party =
				(guest.presence === "no" && "") ||
				(guest.party === "no" && "Blijft niet") ||
				(guest.party === "half" && "blijft even") ||
				(guest.party === "full" && "Blijft hele avond") ||
				(!guest.party && "Niet ingevuld") ||
				(!row.dayGuest && "Avondgast") ||
				(!row.formFilled && "");
			newObj.allergies = guest.allergies ? "Ja" : "Niet opgegeven";
			newObj.allergieInfo = guest.allergieInfo ? guest.allergieInfo : "Geen";
			rows.push(newObj);
		}
	}

	// Add Array Rows
	worksheet.addRows(rows);

	function saveAs(blob, filename) {
		const a = document.createElement("a");
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = filename;
		a.click();
		URL.revokeObjectURL(url);
	}

	const buffer = await workbook.xlsx.writeBuffer();
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	let EXCEL_EXTENSION = ".xlsx";
	const blob = new Blob([buffer], {type: fileType});
	saveAs(blob, "guestlist" + EXCEL_EXTENSION);
};

export const printPostageExcel = async (guestList) => {
	let workbook = new excel.Workbook(); //creating workbook
	let worksheet = workbook.addWorksheet("Customers"); //creating worksheet

	//  WorkSheet Header
	worksheet.columns = [
		{header: "Invite Name", key: "name", width: 25},
		{header: "Passcode", key: "otp", width: 25},
		{header: "Dag/Avond gast", key: "dag", width: 25},
	];

	const rows = [];
	for (let row of guestList) {
		let newObj = {};
		newObj.name = row.familyName;
		newObj.otp = row.otp;
		newObj.dag = row.dayGuest ? "14:30" : "20:30";
		rows.push(newObj);
	}

	// Add Array Rows
	worksheet.addRows(rows);

	function saveAs(blob, filename) {
		const a = document.createElement("a");
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = filename;
		a.click();
		URL.revokeObjectURL(url);
	}

	const buffer = await workbook.xlsx.writeBuffer();
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	let EXCEL_EXTENSION = ".xlsx";
	const blob = new Blob([buffer], {type: fileType});
	saveAs(blob, "guestlist" + EXCEL_EXTENSION);
};
