import React from "react";
import {useAuth} from "../../provider/AuthProvider";
import {Link, Navigate} from "react-router-dom";
import {Button} from "@mui/material";
import {getAuth, signOut} from "firebase/auth";
import useNotification from "../../hooks/useNotification";

import {db} from "../../helpers/FirebaseInit";
import {
	collection,
	query,
	where,
	getDocs,
	doc,
	updateDoc,
	deleteField,
} from "firebase/firestore";

export default function AdminMain() {
	const {currentUser} = useAuth();
	const {addNotification} = useNotification();

	const logoutHandler = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				addNotification("Succesvol uitgelogd", "success");
				Navigate("/");
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	};

	// const deleteFieldsHandler = async () => {
	// 	const q = query(collection(db, "guests"));

	// 	const querySnapshot = await getDocs(q);
	// 	querySnapshot.forEach(async (doc) => {
	// 		let guestNames = doc.data().guestNames;
	// 		guestNames.map((guestName) => {
	// 			delete guestName.allergies;
	// 		});
	// 		console.log(guestNames);
	// 		await updateDoc(doc.ref, {
	// 			guestNames: guestNames,
	// 		});
	// 	});
	// };

	return (
		<div>
			AdminMain
			<div>Logged in as: {currentUser.email} </div>
			<Link to='/admin/addguest'>Addguest</Link>
			<div></div>
			<Link to='/admin/guestlist'>GuestList</Link>
			<Link to='/admin/noaccount'>No account made</Link>
			<div>
				<Button onClick={logoutHandler}>Logout</Button>
			</div>
		</div>
	);
}
