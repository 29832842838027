import SignInSide from "../components/SignInSide";
import Menu from "../components/Menu/index";

export const LoginScreen = () => {
	return (
		<div>
			<SignInSide />
		</div>
	);
};
