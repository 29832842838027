import React, {useContext, useEffect, useState} from "react";
import {auth, db} from "../helpers/FirebaseInit.js";
import {
	connectAuthEmulator,
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
	browserLocalPersistence,
} from "firebase/auth";
import {connectFirestoreEmulator, doc, getDoc} from "firebase/firestore";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {getApp} from "firebase/app";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

setPersistence(auth, browserLocalPersistence);
if (process.env.REACT_APP_environmentMode === "development") {
	connectAuthEmulator(auth, "http://localhost:5002");
	connectFirestoreEmulator(db, "localhost", 8080);

	const functions = getFunctions(getApp());
	connectFunctionsEmulator(functions, "localhost", 5001);
}

export default function AuthProvider({children}) {
	const [currentUser, setCurrentUser] = useState();
	const [isAdmin, setIsAdmin] = useState();
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState();

	function signup(email) {
		return createUserWithEmailAndPassword(
			auth,
			`${email}@mail.com`,
			"password"
		);
	}

	function login(email, password) {
		return signInWithEmailAndPassword(auth, `${email}@mail.com`, password);
	}
	function loginAdmin(name, password) {
		return signInWithEmailAndPassword(auth, `${name}`, password);
	}
	async function reloadUserData() {
		setLoading(true);
		const docRef = doc(db, "guests", currentUser.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setUserData(docSnap.data());
		}
		auth.currentUser.getIdTokenResult().then((token) => {
			setIsAdmin(token.claims.admin);
			setLoading(false);
		});
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			setCurrentUser(user);
			if (user) {
				auth.currentUser.getIdTokenResult().then((token) => {
					setIsAdmin(token.claims.admin);
				});
				const docRef = doc(db, "guests", user.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					setUserData(docSnap.data());
				}
				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	}, [loading]);

	const value = {
		currentUser,
		signup,
		login,
		loginAdmin,
		isAdmin,
		userData,
		reloadUserData,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
