import {Box} from "@mui/material";
import {Layout} from "../../components/Layout";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import {useLanguage} from "../../provider/LanguageProvider";

export const OvernachtenScreen = () => {
	const {language} = useLanguage();
	return (
		<Layout>
			<Box
				sx={{
					padding: {
						xs: "0px 20px 0px 20px",
						sm: "200px 30px 0px 30px",
					},
				}}
				className='overnachten_body'
			>
				<div className='overnachten_header-container'>
					<div className='overnachten_header-background'></div>
					<h2 className='overnachten_header'>
						{language === "dutch" ? "Overnachten" : "Accomodation"}
					</h2>
				</div>

				{language === "dutch" ? (
					<p className='overnachten_paragraph'>
						Het is mogelijk om in de buurt van onze trouwlocatie te overnachten.
						Wij hebben voor onze gasten een blokreservering gemaakt bij het
						hotel:
					</p>
				) : (
					<p className='overnachten_paragraph'>
						There’s a hotel near our wedding location where you can stay the
						night. We have made a block booking at the following hotel:
					</p>
				)}

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<HotelIcon
						sx={{
							marginRight: "20px",
						}}
					/>
					<h3 className='text-emphasis'>
						<a href='https://www.amptvannijkerk.nl/' target='blank'>
							Het Ampt van Nijkerk
						</a>
					</h3>
				</Box>

				<Box
					sx={{
						maxWidth: "100%",
						height: "300px",
						margin: "0px 0px 25px 0px",
						overflow: "hidden",
						borderRadius: "10px",

						backgroundImage:
							"url(https://media.iceportal.com/25516/photos/0665710_XL.jpg)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						opacity: "90%",
						position: "relative",
						zIndex: "-1",
					}}
				></Box>

				{language === "dutch" ? (
					<p className='overnachten_paragraph'>
						Dit hotel ligt op een klein kwartiertje lopen of een paar minuten
						rijden van onze trouwlocatie.
					</p>
				) : (
					<p className='overnachten_paragraph'>
						By foot, it takes you around 15 minutes to get from the hotel to the
						wedding location.
					</p>
				)}
				{language === "dutch" ? (
					<p className='overnachten_paragraph'>
						Als je bij het boeken van een kamer
						<b> referentienummer 138143 </b>
						doorgeeft, kom je in onze blokreservering terecht.
					</p>
				) : (
					<p className='overnachten_paragraph'>
						To join our block booking, please use the
						<b> reference number 138143 </b>
						when making a reservation.
					</p>
				)}

				{language === "dutch" ? (
					<>
						<p className='overnachten_paragraph'>
							Overnachten in het hotel is voor eigen rekening. Wij hebben
							daarvoor deze tarieven afgesproken:
						</p>
						<p className='overnachten_paragraph'>
							<div>
								<b>Tweepersoonskamers:</b> 114 euro incl. ontbijt.
							</div>
							<div>
								<b>Eenpersoonskamers:</b> 104 euro incl. ontbijt.
							</div>
						</p>
						<p className='overnachten_paragraph'>
							Familiekamers zijn ook beschikbaar, op aanvraag.
						</p>

						<p className='overnachten_paragraph'>
							Mocht je in het hotel verblijven, dan kun je je auto daar
							eventueel alvast neerzetten en naar de locatie komen lopen.
						</p>
					</>
				) : (
					<>
						<p className='overnachten_paragraph'>
							A stay at the hotel is at your own expense. We have negotiated the
							following rates with the hotel:
						</p>
						<p className='overnachten_paragraph'>
							<div>
								<b>Double room:</b> 114 euro per night, including breakfast.
							</div>
							<div>
								<b>Single room:</b> 104 euro per night, including breakfast.
							</div>
						</p>
						<p className='overnachten_paragraph'>
							Family rooms are also available on request.
						</p>

						<p className='overnachten_paragraph'>
							If you are staying in the hotel, you can leave your car at the
							hotel parking and walk to the wedding location.
						</p>
					</>
				)}
				<Box
					sx={{
						marginTop: "80px",
					}}
				/>

				{language === "dutch" ? (
					<>
						<p className='overnachten_paragraph'>
							Een andere hotel optie in de buurt is:
						</p>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<HotelIcon
								sx={{
									marginRight: "20px",
								}}
							/>
							<h3 className='text-emphasis'>
								<a href='https://deroodeschuur.nl/' target='blank'>
									Hotel De Roode Schuur
								</a>
							</h3>
						</Box>

						<Box
							sx={{
								maxWidth: "100%",
								height: "300px",
								margin: "0px 0px 25px 0px",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage:
									"url(https://img.hotelspecials.nl/9c6f167e8feacd2856fb23e184fd760c.jpeg?w=800&h=528&c=1&quality=70)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "90%",
								position: "relative",
								zIndex: "-1",
							}}
						></Box>
						<p className='overnachten_paragraph'>
							Bij dit hotel hebben wij geen blok reservering gemaakt. Dit hotel
							ligt niet op loopafstand. Je zult dus een taxi moeten boeken of
							met je eigen auto naar het hotel moeten rijden.
						</p>
					</>
				) : (
					<>
						<p className='overnachten_paragraph'>Another hotel option is:</p>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<HotelIcon
								sx={{
									marginRight: "20px",
								}}
							/>
							<h3 className='text-emphasis'>
								<a href='https://deroodeschuur.nl/' target='blank'>
									Hotel De Roode Schuur
								</a>
							</h3>
						</Box>

						<Box
							sx={{
								maxWidth: "100%",
								height: "300px",
								margin: "0px 0px 25px 0px",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage:
									"url(https://img.hotelspecials.nl/9c6f167e8feacd2856fb23e184fd760c.jpeg?w=800&h=528&c=1&quality=70)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "90%",
								position: "relative",
								zIndex: "-1",
							}}
						></Box>
						<p className='overnachten_paragraph'>
							This hotel is not within walking distance of the wedding location.
							We did not make a block booking at this hotel.
						</p>
					</>
				)}
			</Box>

			<Box
				sx={{
					padding: {
						xs: "0px 35px 100px 35px",
						sm: "0px 70px 200px 70px",
					},
				}}
			></Box>
		</Layout>
	);
};
