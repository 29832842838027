import {Box} from "@mui/material";

import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";

export const SpeechesScreen = () => {
	const {language} = useLanguage();

	return (
		<Layout>
			<div className='speeches_header-container'>
				<div className='speeches_header-background'></div>
				<h2 className='speeches_header'>Speeches</h2>
			</div>

			{language === "dutch" ? (
				<>
					<Box
						sx={{
							padding: {
								xs: "0px 20px 100px 20px",
								sm: "200px 30px 0px 30px",
							},
						}}
					>
						<p className='speeches_paragraph'>
							Wil je ons verrassen met een speech of voordracht? Wat leuk!
							Overleg dit alsjeblieft met Sandra en Rick.
						</p>

						<p className='speeches_paragraph'>
							Je kunt contact opnemen met onze ceremoniemeesters via onderstaand
							e-mailadres:
						</p>
						<p className='speeches_paragraph'>
							<b>
								<a href='mailto:ceremoniemeesters@irisenrobin.nl'>
									ceremoniemeesters@irisenrobin.nl
								</a>
							</b>
						</p>

						<Box
							sx={{
								maxWidth: "100%",
								height: "300px",
								margin: "50px 0px 25px 0px",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage:
									"url(https://images.unsplash.com/photo-1527529482837-4698179dc6ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "80%",
								position: "relative",
								zIndex: "-1",
							}}
						></Box>
					</Box>
				</>
			) : (
				<>
					<Box
						sx={{
							padding: {
								xs: "0px 20px 100px 20px",
								sm: "200px 30px 0px 30px",
							},
						}}
					>
						<p className='speeches_paragraph'>
							Would you like to surprise us with a speech? How nice! Please
							discuss this with our masters of ceremony.
						</p>

						<p className='speeches_paragraph'>
							You can reach Sandra and Rick via this email address:
						</p>
						<p className='speeches_paragraph'>
							<b>
								<a href='mailto:ceremoniemeesters@irisenrobin.nl'>
									ceremoniemeesters@irisenrobin.nl
								</a>
							</b>
						</p>

						<Box
							sx={{
								maxWidth: "100%",
								height: "300px",
								margin: "50px 0px 25px 0px",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage:
									"url(https://images.unsplash.com/photo-1527529482837-4698179dc6ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "80%",
								position: "relative",
								zIndex: "-1",
							}}
						></Box>
					</Box>
				</>
			)}
		</Layout>
	);
};
