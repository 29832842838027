import * as React from "react";
import "./menu.css";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";

import {getAuth, signOut} from "firebase/auth";
import useNotification from "../../hooks/useNotification";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../provider/LanguageProvider";

import {useAuth} from "../../provider/AuthProvider";

export default function Menu() {
	const {addNotification} = useNotification();
	const navigate = useNavigate();
	const {currentUser, userData, isAdmin} = useAuth();
	const {language, switchLanguage} = useLanguage();

	const [menuOpening, setMenuOpening] = React.useState(false);

	const [menuClosing, setMenuClosing] = React.useState(false);

	const [mountMenu, setMountMenu] = React.useState(false);

	const openMenu = () => {
		setMountMenu(true);
		setMenuClosing(true);
		setTimeout(() => {
			setMenuClosing(false);
			setMenuOpening(true);
		}, 10);
	};
	const closeMenu = () => {
		setMenuOpening(false);
		setMenuClosing(true);
		setTimeout(() => {
			setMountMenu(false);
		}, 500);
	};
	const handleSwitchLanguage = () => {
		switchLanguage();
		closeMenu();
	};

	const navigateToSection = (section) => {
		navigate("/informatie");
		closeMenu();
		setTimeout(() => {
			console.log("function has run");
			const element = document.getElementById(`${section}`);
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "nearest",
				alignToTop: true,
			});
		}, 200);
	};

	const navigateTo = (page) => {
		navigate(`/${page}`);
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 10);
		closeMenu();
	};

	const logoutHandler = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				addNotification("Succesvol uitgelogd", "success");
				navigate("/");
				closeMenu();
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	};

	return (
		<>
			{mountMenu && (
				<div
					className={`menu_container ${menuClosing ? "hidden" : ""} ${
						menuOpening ? "showing" : ""
					}`}
				>
					<>
						<div className='menu_backdrop' onClick={() => closeMenu()}></div>
						<div className='menu_inner'>
							<div
								className='menu_inner-closebutton'
								onClick={() => {
									closeMenu();
								}}
							>
								<CloseIcon></CloseIcon>
							</div>

							<h2 className='menu_header'>Iris & Robin</h2>
							{currentUser && (
								<ul className='menu_link-list'>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("home")}>Home</span>
									</li>

									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("RSVP")}>RSVP</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("programma")}>
											{language === "dutch" ? "Programma" : "Programme"}
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("dresscode")}>
											Dresscode
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("locatie")}>
											{language === "dutch" ? "Locatie" : "Location"}
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("parkeren")}>
											{language === "dutch"
												? "Parkeren en OV"
												: "Parking & transportation"}
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("overnachten")}>
											{language === "dutch" ? "Overnachten" : "Accommodation"}
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("speeches")}>Speeches</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("cadeautip")}>
											{language === "dutch" ? "Cadeautip" : "Gift ideas"}
										</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("contact")}>Contact</span>
									</li>
								</ul>
							)}

							{currentUser && isAdmin && (
								<>
									<ul className='menu_link-list'>
										<li className='menu_link-listitem'>
											<span onClick={() => navigateTo("admin/main")}>
												Admin Panel
											</span>
										</li>
										<li className='menu_link-listitem'>
											<span onClick={() => navigateTo("admin/addguest")}>
												Add guest
											</span>
										</li>
										<li className='menu_link-listitem'>
											<span onClick={() => navigateTo("admin/guestlist")}>
												Guest list
											</span>
										</li>
									</ul>
									{/* <div className='menu_login-info'>
										{language === "dutch" ? "Ingelogd als" : "Logged in as"}

										<div className='menu_login-info-username'>
											{currentUser.displayName}
										</div>
										<div className='menu_login-logout' onClick={logoutHandler}>
											<span className='menu_login-logout-text'>
												{language === "dutch" ? "Log uit" : "Log out"}
											</span>
											<LogoutIcon />
										</div>
									</div> */}
								</>
							)}

							{!currentUser && (
								<ul className='menu_link-list'>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("")}>Home</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("login")}>Login</span>
									</li>
									<li className='menu_link-listitem'>
										<span onClick={() => navigateTo("register")}>
											{language === "dutch" ? "Maak account" : "Create account"}
										</span>
									</li>
								</ul>
							)}
							<div className='menu_language'>
								<button
									className='menu_language-header'
									onClick={handleSwitchLanguage}
								>
									{language === "dutch"
										? "Switch to English"
										: "Wissel naar Nederlands"}
								</button>
							</div>
							{currentUser && userData && userData.familyName && (
								<div className='menu_login-info'>
									Ingelogd als:
									<div className='menu_login-info-username'>
										{userData.familyName}
									</div>
									<div className='menu_login-logout' onClick={logoutHandler}>
										<span className='menu_login-logout-text'>Log uit</span>
										<LogoutIcon />
									</div>
								</div>
							)}
						</div>
					</>
				</div>
			)}
			<div
				className='menuButton'
				onClick={() => {
					openMenu();
				}}
			>
				<MenuIcon />
				<div className='menuButton-text'>Menu</div>
			</div>
		</>
	);
}

{
	/* <div className='menu_language'>
							<div className='menu_language-header'>
								{language === "dutch" ? "Kies uw taal" : "Choose your language"}
							</div>
							<div className='menu_language-item'>English</div>
							<div className='menu_language-item'>Nederlands</div>
						</div> */
}
