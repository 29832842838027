import "./App.css";

import {Routes, Route} from "react-router-dom";
import {AccountScreen} from "./screens/AccountScreen";
import {AdminCreateUser} from "./screens/admin/AdminCreateUser";
import NotificationProvider from "./provider/NotificationProvider";
import LanguageProvider from "./provider/LanguageProvider";
import {ErrorAlert} from "./components/ErrorAlert";
import AuthProvider from "./provider/AuthProvider";
import {ProtectedRoute} from "./helpers/ProtectedRoute";
import {LoginScreen} from "./screens/LoginScreen";
import AdminLogin from "./screens/admin/AdminLogin";
import AdminMain from "./screens/admin/AdminMain";
import {AdminRoute} from "./helpers/AdminRoute";
import {AdminAddGuestScreen} from "./screens/admin/AdminAddGuest";
import {RegisterScreen} from "./screens/RegisterScreen";
import {DresscodeScreen} from "./screens/guest/DresscodeScreen";
import {InformatieScreen} from "./screens/InformatieScreen";
import {PlanningScreen} from "./screens/guest/PlanningScreen";
import {
	CadeauScreen,
	HomeScreen,
	LocatieScreen,
	OvernachtenScreen,
	ParkerenScreen,
	ProgrammaScreen,
	RsvpScreen,
	SpeechesScreen,
	ContactScreen,
} from "./screens/exports";
import {LandingScreen} from "./screens/LandingScreen";
import {AdminGuestListScreen} from "./screens/admin/AdminGuestList";
import {AdminGuestDetailsScreen} from "./screens/admin/AdminGuestDetails";
import Menu from "./components/Menu";

function App() {
	return (
		<div className='App'>
			<NotificationProvider>
				<LanguageProvider>
					<ErrorAlert />
					<AuthProvider>
						<Menu />
						<Routes>
							<Route path='/login' element={<LoginScreen />} />
							<Route path='/register' element={<RegisterScreen />} />

							<Route
								path='/home'
								element={
									<ProtectedRoute>
										<HomeScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/RSVP'
								element={
									<ProtectedRoute>
										<RsvpScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/programma'
								element={
									<ProtectedRoute>
										<ProgrammaScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/dresscode'
								element={
									<ProtectedRoute>
										<DresscodeScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/locatie'
								element={
									<ProtectedRoute>
										<LocatieScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/parkeren'
								element={
									<ProtectedRoute>
										<ParkerenScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/overnachten'
								element={
									<ProtectedRoute>
										<OvernachtenScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/speeches'
								element={
									<ProtectedRoute>
										<SpeechesScreen />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/cadeautip'
								element={
									<ProtectedRoute>
										<CadeauScreen />
									</ProtectedRoute>
								}
							/>

							<Route
								path='/contact'
								element={
									<ProtectedRoute>
										<ContactScreen />
									</ProtectedRoute>
								}
							/>

							<Route path='/' element={<LandingScreen />} />

							<Route
								path='/admin/createuser'
								element={
									<AdminRoute>
										<AdminCreateUser />
									</AdminRoute>
								}
							/>
							<Route
								path='/admin/addguest'
								element={
									<AdminRoute>
										<AdminAddGuestScreen />
									</AdminRoute>
								}
							/>
							<Route
								path='/admin/guest/:guestId'
								element={
									<AdminRoute>
										<AdminGuestDetailsScreen />
									</AdminRoute>
								}
							/>
							<Route path='/admin/login' element={<AdminLogin />} />
							<Route
								path='/admin/main'
								element={
									<AdminRoute>
										<AdminMain />
									</AdminRoute>
								}
							/>
							<Route
								path='/admin/guestlist'
								element={
									<AdminRoute>
										<AdminGuestListScreen />
									</AdminRoute>
								}
							/>
						</Routes>
					</AuthProvider>
				</LanguageProvider>
			</NotificationProvider>
		</div>
	);
}

export default App;
