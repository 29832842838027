import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getFunctions} from "firebase/functions";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";

const firebaseConfig = {
	apiKey: "AIzaSyAROdczirud6cdwiovjR8UA5adzeqA6qYQ",
	authDomain: "bruiloft0209.firebaseapp.com",
	projectId: "bruiloft0209",
	storageBucket: "bruiloft0209.appspot.com",
	messagingSenderId: "529403358268",
	appId: "1:529403358268:web:e7ed577eee0b1469d52739",
	measurementId: "G-8G8DPXPE8H",
};

export const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase);

export const db = getFirestore(firebase);
export const functions = getFunctions(firebase);

// export const appCheck = initializeAppCheck(firebase, {
// 	provider: new ReCaptchaV3Provider("6Lcg0v8kAAAAANmunY-DlrrBanqgDgPenD8XV76T"),
// 	isTokenAutoRefreshEnabled: true,
// });
