import {Box} from "@mui/material";
import {Layout} from "../../components/Layout";
import Avatar from "@mui/material/Avatar";
import {db} from "../../helpers/FirebaseInit";
import {useEffect, useState} from "react";
import {useAuth} from "../../provider/AuthProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoFoodIcon from "@mui/icons-material/NoFood";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import CelebrationIcon from "@mui/icons-material/Celebration";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

import {
	Divider,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Select,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useNotification from "../../hooks/useNotification";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import Loader from "../../components/Loader";
import {
	GreenFormControl,
	GreenTextField,
	StyledButton,
} from "../../components/ThemedComponents";
import {useLanguage} from "../../provider/LanguageProvider";

export const RsvpScreen = () => {
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState({});
	const [editing, setEditing] = useState(false);
	const [editingEmail, setEditingEmail] = useState(true);
	const [allergies, setAllergies] = useState(false);
	const [guestData, setGuestData] = useState([]);
	const [guestEmail, setGuestEmail] = useState("");
	const navigate = useNavigate();

	const {addNotification} = useNotification();
	const {currentUser} = useAuth();
	const {language} = useLanguage();

	const handleSubmit = (event) => {
		event.preventDefault();

		const addData = async (guestData) => {
			try {
				const docRef = doc(db, "guests", currentUser.uid);
				await updateDoc(docRef, {
					guestNames: guestData,
					formFilled: true,
				});
				if (allergies) {
					await updateDoc(docRef, {
						guestNames: guestData,
						formFilled: true,
						hasAllergie: true,
					});
				}
			} catch (e) {
				console.error("Error adding document: ", e);
			}
		};
		addData(guestData);
		setLoading(true);
		setEditing(false);
		addNotification("Gegevens opgeslagen", "success");
	};
	const saveEmail = async () => {
		try {
			const docRef = doc(db, "guests", currentUser.uid);
			await updateDoc(docRef, {
				email: guestEmail,
			});
		} catch (e) {
			console.error("Error adding document: ", e);
		}
		setEditingEmail((prev) => !prev);
	};

	const printPresence = (presence) => {
		if (presence === "yes") {
			return "Ja";
		}
		if (presence === "no") {
			return "Nee";
		}
		if (presence === "maybe") {
			return "Misschien";
		}
	};
	const printPartyPresence = (presence) => {
		if (presence === "full") {
			return "Ja, tot het einde.";
		}
		if (presence === "half") {
			return "Alleen aan het begin.";
		}
		if (presence === "no") {
			return "Nee";
		}
	};

	const fetchUserData = async () => {
		const docRef = doc(db, "guests", currentUser.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setUserData(docSnap.data());
			setGuestData(docSnap.data().guestNames);
			if (docSnap.data().email) {
				setGuestEmail(docSnap.data().email);
				setEditingEmail(false);
			}
			setLoading(false);
		} else {
			console.log("No such document!");
			setLoading(false);
		}
	};

	const handleSelectChange = (value, index) => {
		const tmpArray = [...guestData];
		tmpArray[index].presence = value;
		setGuestData(tmpArray);
	};
	const handlePartyChange = (value, index) => {
		const tmpArray = [...guestData];
		tmpArray[index].party = value;
		setGuestData(tmpArray);
	};
	const handleAllergieSelectChange = (value, index) => {
		if (value) {
			setAllergies(true);
		}
		const tmpArray = [...guestData];
		tmpArray[index].allergies = value;
		setGuestData(tmpArray);
	};

	const handleAllergiesChange = (value, index) => {
		const tmpArray = [...guestData];
		tmpArray[index].allergieInfo = value;
		setGuestData(tmpArray);
	};
	const handleLiedjeChange = (value, index) => {
		const tmpArray = [...guestData];
		tmpArray[index].song = value;
		setGuestData(tmpArray);
	};

	useEffect(() => {
		fetchUserData();
	}, [loading]);

	return (
		<Layout>
			<div className='rsvp_header-container'>
				<div className='rsvp_header-background'></div>
				<h2 className='rsvp_header'>RSVP</h2>
			</div>
			<div>
				<Box
					sx={{
						marginTop: {
							sm: "250px",
						},
					}}
				>
					<h3 className='rsvp_small-header'>
						{!loading && userData && " " + userData.familyName}
					</h3>
				</Box>
				<Box
					sx={{
						maxWidth: "400px",
						marginTop: "20px",
						margin: "20px auto",
					}}
				>
					{language === "dutch" ? (
						<p className='rsvp_paragraph'>
							Via deze pagina kunnen jullie ons laten weten of jullie bij onze
							bruiloft zijn. Laat ons ook gerust weten of je dieetwensen hebt.
							Als laatste zijn we benieuwd wat je ons mee zou willen geven voor
							ons huwelijk - een tip, een herinnering of een spreuk, we vinden
							het allemaal leuk!
						</p>
					) : (
						<p className='rsvp_paragraph'>
							Via this page you can let us know whether you will be attending
							our wedding. Please also indicate any dietary requirements. Lastly
							we would love for you to share something with us for our marriage
							- a piece of advice, an anecdote or your favorite quote, it's all
							welcome!
						</p>
					)}
				</Box>
			</div>

			{loading && <Loader />}
			<Box
				sx={{
					marginTop: "50px",
					marginBottom: "75px",
					position: "relative",
					zIndex: "-1",
				}}
				// component='rsvp_form'
				onSubmit={handleSubmit}
			>
				<div className='rsvp_form'>
					{!loading &&
						guestData &&
						// guestData.length > 1 &&
						(!userData.formFilled || editing) &&
						guestData.map((guest, index) => (
							<Box
								sx={{
									"&:not(:last-child)": {
										marginBottom: "100px",
									},
									"&:last-child": {
										marginBottom: "50px",
									},
								}}
								key={guest.idNum}
							>
								{guestData.length > 1 && (
									<h3 className='rsvp_small-header'>{guest.name}</h3>
								)}
								<Box
									sx={{
										bgcolor: "background.paper",
										borderRadius: "10px",
										boxShadow: "5px 5px 10px rgba(1, 41, 1, 0.10)",
										padding: "15px 15px 25px 15px",
									}}
								>
									<GreenFormControl
										variant='standard'
										sx={{
											minWidth: 120,
											width: "100%",
										}}
									>
										<InputLabel id='simple-select-standard-label'>
											{language === "dutch"
												? "Ben je aanwezig op onze bruiloft?"
												: "Will you attend our wedding?"}
										</InputLabel>
										<Select
											labelId='simple-select-standard-label'
											id='simple-select-standard'
											label='Aanwezig'
											value={guestData[index].presence || ""}
											onChange={(e) =>
												handleSelectChange(e.target.value, index)
											}
										>
											<MenuItem value=''>
												<em></em>
											</MenuItem>
											<MenuItem value={"yes"}>
												{language === "dutch" ? "Ja" : "Yes"}
											</MenuItem>
											<MenuItem value={"no"}>
												{language === "dutch" ? "Nee" : "No"}
											</MenuItem>
											<MenuItem value={"maybe"}>
												{language === "dutch" ? "Misschien" : "Maybe"}
											</MenuItem>
										</Select>
									</GreenFormControl>

									{userData.dayGuest && (
										<GreenFormControl
											variant='standard'
											sx={{
												minWidth: 120,
												width: "100%",
												marginTop: "20px",
											}}
										>
											<InputLabel id='simple-select-standard-label'>
												{language === "dutch"
													? "Blijf je ook voor ons trouwfeest?"
													: "Will you stay for the party?"}
											</InputLabel>
											<Select
												labelId='simple-select-standard-label'
												id='simple-select-standard'
												label='Aanwezig'
												value={guestData[index].party || ""}
												onChange={(e) =>
													handlePartyChange(e.target.value, index)
												}
											>
												<MenuItem value=''>
													<em></em>
												</MenuItem>
												<MenuItem value={"full"}>
													{language === "dutch"
														? "Jazeker, ik blijf tot de laatste dans!"
														: "Yes, I’ll give it all I have!"}
												</MenuItem>
												<MenuItem value={"half"}>
													{language === "dutch"
														? "Ik blijf wel even, maar ben van plan op tijd weer naar huis te gaan."
														: "I’ll stay for a while, but will go home early"}
												</MenuItem>
												<MenuItem value={"no"}>
													{language === "dutch"
														? "Nee, na het diner ga ik er vandoor!"
														: "No, I’ll leave after dinner"}
												</MenuItem>
											</Select>
										</GreenFormControl>
									)}

									<GreenFormControl
										variant='standard'
										sx={{
											marginTop: "30px",
											minWidth: 120,
											width: "100%",
										}}
									>
										<InputLabel id='simple-select-standard-label'>
											{language === "dutch"
												? "Heb je dieetwensen?"
												: "Do you have any dietary requirements?"}
										</InputLabel>
										<Select
											labelId='simple-select-standard-label'
											id='simple-select-standard'
											label='Allergieën'
											value={guestData[index].allergies || ""}
											onChange={(e) =>
												handleAllergieSelectChange(e.target.value, index)
											}
										>
											<MenuItem value=''>
												<em></em>
											</MenuItem>
											<MenuItem value={true}>
												{language === "dutch" ? "Ja" : "Yes"}
											</MenuItem>
											<MenuItem value={false}>
												{language === "dutch" ? "Nee" : "No"}
											</MenuItem>
										</Select>
									</GreenFormControl>
									{guestData[index].allergies && (
										<GreenTextField
											sx={{marginTop: "30px"}}
											fullWidth
											id='standard-basic'
											label={
												language === "dutch"
													? "Allergiën of dieet vereisten"
													: "Allergies or dietary requirements"
											}
											variant='standard'
											onChange={(e) =>
												handleAllergiesChange(e.target.value, index)
											}
											value={guestData[index].allergieInfo}
										/>
									)}

									<GreenTextField
										sx={{marginTop: "30px", "& label": {fontSize: "0.85em"}}}
										fullWidth
										multiline
										id='standard-basic'
										label={
											language === "dutch"
												? "Wat zou je ons voor ons huwelijk willen meegeven?"
												: "What would you like to share with us for our marriage?"
										}
										variant='standard'
										onChange={(e) => handleLiedjeChange(e.target.value, index)}
										value={guestData[index].song}
									/>
								</Box>
							</Box>
						))}
					{!loading &&
						guestData &&
						// guestData.length > 1 &&
						userData.formFilled &&
						!editing &&
						guestData.map((guest, index) => (
							<Box
								sx={{
									"&:not(:last-child)": {
										marginBottom: "50px",
									},
									"&:last-child": {
										marginBottom: "30px",
									},
								}}
								key={guest.idNum}
							>
								{guestData.length > 1 && (
									<h3 className='rsvp_small-header'>{guest.name}</h3>
								)}

								<Box
									sx={{
										width: "400px",
										maxWidth: "90vw",
										marginLeft: "auto",
										marginRight: "auto",
										borderRadius: "10px",
										display: "flex",
										justifyContent: "center",
										zIndex: "-1",
										position: "relative",
									}}
								>
									<List
										sx={{
											width: "100%",
											maxWidth: "360px",
											bgcolor: "background.paper",
											borderRadius: "10px",
											boxShadow: "5px 5px 10px rgba(1, 41, 1, 0.10)",
										}}
									>
										<ListItem>
											<ListItemAvatar>
												<Avatar sx={{backgroundColor: "#2a5b18"}}>
													{guest.presence === "yes" ? (
														<EventAvailableIcon />
													) : (
														<EventBusyIcon />
													)}
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={
													language === "dutch"
														? "Ben je aanwezig op onze bruiloft?"
														: "Will you attend our wedding?"
												}
												secondary={printPresence(guest.presence)}
											/>
										</ListItem>
										<Divider variant='inset' component='li' />

										{userData.dayGuest && (
											<>
												<ListItem>
													<ListItemAvatar>
														<Avatar sx={{backgroundColor: "#2a5b18"}}>
															<CelebrationIcon />
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary={
															language === "dutch"
																? "Blijf je ook voor ons trouwfeest?"
																: "Will you stay for the party?"
														}
														secondary={printPartyPresence(guest.party)}
													/>
												</ListItem>
												<Divider variant='inset' component='li' />
											</>
										)}

										<ListItem>
											<ListItemAvatar>
												<Avatar sx={{backgroundColor: "#2a5b18"}}>
													<NoFoodIcon />
												</Avatar>
											</ListItemAvatar>
											{language === "dutch" ? (
												<ListItemText
													primary='Dieetwensen'
													secondary={
														guest.allergies
															? guest.allergieInfo
															: "Je hebt geen dieetwensen opgegeven."
													}
												/>
											) : (
												<ListItemText
													primary='Dietary requirements'
													secondary={
														guest.allergies
															? guest.allergieInfo
															: "I don’t have any dietary requirements"
													}
												/>
											)}
										</ListItem>
										<Divider variant='inset' component='li' />

										<ListItem>
											<ListItemAvatar>
												<Avatar sx={{backgroundColor: "#2a5b18"}}>
													<TipsAndUpdatesIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={
													language === "dutch"
														? "Wat wil je ons meegeven?"
														: "What would you like to share with us for our marriage?"
												}
												secondary={guest.song}
											/>
										</ListItem>
									</List>
								</Box>
							</Box>
						))}

					<Box sx={{marginTop: "20px", marginBottom: "40px"}}>
						{(editing || !userData.formFilled) && (
							<StyledButton
								type='submit'
								variant='contained'
								onClick={handleSubmit}
							>
								{language === "dutch" ? "Opslaan" : "Save"}
							</StyledButton>
						)}
						{!editing && (
							<StyledButton
								variant='text'
								sx={{color: "primary.main", marginLeft: "10px"}}
								onClick={() => {
									setEditing((prev) => !prev);
								}}
							>
								{language === "dutch"
									? "Wijzig je antwoorden"
									: "Change answers"}
							</StyledButton>
						)}
						{editing && (
							<StyledButton
								variant='text'
								sx={{color: "primary.main", marginLeft: "10px"}}
								onClick={() => {
									setEditing((prev) => !prev);
								}}
							>
								{language === "dutch"
									? "Annuleer wijzigingen"
									: "Cancel changes"}
							</StyledButton>
						)}
					</Box>

					{/* -------  	EMAIL FORM		------- */}
					<Box
						sx={{
							maxWidth: "400px",
							margin: "60px auto 20px auto",
						}}
					>
						<h3 className='rsvp_small-header'>Email</h3>
						{language === "dutch" ? (
							<p className='rsvp_paragraph'>
								In de weken na de bruiloft zouden wij eventueel nog met onze
								gasten contact op willen nemen. Bijvoorbeeld om de leuke foto's
								te delen met jullie! Vind jij het nu leuk om nog van ons te
								horen? Laat dan hieronder je e-mail adres achter.
							</p>
						) : (
							<p className='rsvp_paragraph'>
								In the weeks after the wedding we might like to get in touch
								with you. For example to share some of the pictures of the
								wedding with you! Would you like to hear from us after the
								wedding? Then leave your email address below.
							</p>
						)}
					</Box>

					<Box
						sx={{
							"&:not(:last-child)": {
								marginBottom: "50px",
							},
							"&:last-child": {
								marginBottom: "30px",
							},
						}}
					>
						<Box
							sx={{
								width: "400px",
								maxWidth: "90vw",
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: "20px",
								marginTop: "40px",
								borderRadius: "10px",
								display: "flex",
								justifyContent: "center",
								position: "relative",
							}}
						>
							<List
								sx={{
									width: "100%",
									maxWidth: "360px",
									bgcolor: "background.paper",
									borderRadius: "10px",
									boxShadow: "5px 5px 10px rgba(1, 41, 1, 0.10)",
								}}
							>
								<ListItem>
									<ListItemAvatar>
										<Avatar sx={{backgroundColor: "#2a5b18"}}>
											<MarkEmailReadIcon />
										</Avatar>
									</ListItemAvatar>
									{editingEmail ? (
										<GreenTextField
											sx={{marginTop: "0px"}}
											fullWidth
											id='standard-basic'
											label={language === "dutch" ? "E-mail" : "Email"}
											variant='standard'
											onChange={(e) => setGuestEmail(e.target.value)}
											value={guestEmail}
										/>
									) : (
										<ListItemText
											primary={language === "dutch" ? "E-mail" : "Email"}
											secondary={guestEmail}
										/>
									)}
								</ListItem>
							</List>
						</Box>
						{editingEmail ? (
							<Box sx={{display: "flex", justifyContent: "center"}}>
								<StyledButton
									type='submit'
									variant='contained'
									onClick={saveEmail}
								>
									{language === "dutch" ? "Opslaan" : "Save"}
								</StyledButton>
								{userData && userData.email && (
									<StyledButton
										variant='text'
										sx={{color: "primary.main", marginLeft: "10px"}}
										onClick={() => {
											setEditingEmail((prev) => !prev);
										}}
									>
										{language === "dutch"
											? "Annuleer wijzigingen"
											: "Cancel changes"}
									</StyledButton>
								)}
							</Box>
						) : (
							<StyledButton
								variant='text'
								sx={{color: "primary.main", marginLeft: "10px"}}
								onClick={() => {
									setEditingEmail((prev) => !prev);
								}}
							>
								{language === "dutch" ? "Wijzig e-mail" : "Edit email"}
							</StyledButton>
						)}
					</Box>
				</div>
			</Box>
			<Box
				sx={{
					padding: {
						xs: "0px 35px 100px 35px",
						sm: "0px 70px 200px 70px",
					},
				}}
			></Box>
		</Layout>
	);
};
