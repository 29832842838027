import {Box, Typography} from "@mui/material";

import MarkunreadIcon from "@mui/icons-material/Markunread";
import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";

export const CadeauScreen = () => {
	const {language} = useLanguage();
	return (
		<Layout>
			<div className='cadeau_header-container'>
				<div className='cadeau_header-background'></div>
				<h2 className='cadeau_header'>
					{language === "dutch" ? "Cadeautips" : "Gift ideas"}
				</h2>
			</div>

			<Box
				sx={{
					padding: {
						xs: "0px 20px 100px 20px",
						sm: "170px 30px 100px 30px",
					},
				}}
				className='cadeau_body'
			>
				<Box
					sx={{
						maxWidth: "100%",
						height: "300px",
						margin: "40px 0px 50px 0px",
						overflow: "hidden",
						borderRadius: "10px",

						backgroundImage:
							"url(https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						opacity: "90%",
						position: "relative",
						zIndex: "-1",
					}}
				></Box>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: "40px",
					}}
				>
					<MarkunreadIcon
						sx={{
							marginRight: "20px",
						}}
					/>
					<Typography
						sx={{
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						{language === "dutch" ? "Cadeautips" : "Gift ideas"}
					</Typography>
				</Box>
				{language === "dutch" ? (
					<p className='cadeau_paragraph'>
						Er is niets dat ons gelukkiger kan maken dan jullie aanwezigheid op
						onze bruiloft.
					</p>
				) : (
					<p className='cadeau_paragraph'>
						There is nothing that would make us happier than just your presence
						at our wedding.
					</p>
				)}
				{language === "dutch" ? (
					<p className='cadeau_paragraph'>
						Toch weten wij ook dat velen van jullie de gelegenheid zullen willen
						aangrijpen om ons een cadeau te geven. In dat geval willen wij
						jullie graag onderdeel maken van onze droom om een jaar vrij te
						nemen en daarin een lange fietstocht te maken. Een envelopje brengt
						ons iets dichter bij deze droom in de buurt!
					</p>
				) : (
					<p className='cadeau_paragraph'>
						We realize, however, that there may be some of you that would like
						to seize the opportunity to give us a gift. In that case, we would
						love to share with you our long-term dream of taking a sabbatical
						together to make a long-distance cycling trip. A small financial
						contribution would get us closer to achieving this dream!
					</p>
				)}
			</Box>
		</Layout>
	);
};
