import {ThemeProvider, createTheme} from "@mui/material/styles";
import {Button, FormControl, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";

const theme = createTheme({
	palette: {
		primary: {
			main: "#5d5f20",
		},
		secondary: {
			main: "#5d5f20",
		},
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					"& label.Mui-focused": {
						color: "#5d5f20",
					},
					"& .MuiFilledInput-underline:after": {
						borderBottom: "solid 2px #5d5f20 !important",
					},
				},
			},
		},
	},
});

const GreenTextField = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<TextField {...props} margin='normal' fullWidth>
				font-size: 1rem
			</TextField>
		</ThemeProvider>
	);
};

const StyledButton = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<Button {...props} />
		</ThemeProvider>
	);
};

const StyledLoadingButton = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<LoadingButton {...props} />
		</ThemeProvider>
	);
};

const GreenFormControl = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<FormControl {...props}>{props.children}</FormControl>
		</ThemeProvider>
	);
};

export {StyledButton, GreenTextField, GreenFormControl, StyledLoadingButton};
