import {Box} from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WineBarIcon from "@mui/icons-material/WineBar";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CelebrationIcon from "@mui/icons-material/Celebration";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import {useAuth} from "../../provider/AuthProvider";
import {Layout} from "../../components/Layout";
import {useLanguage} from "../../provider/LanguageProvider";
import {Link} from "react-router-dom";

export const ProgrammaScreen = () => {
	const {currentUser, userData} = useAuth();
	const {language, switchLanguage} = useLanguage();

	return (
		<Layout>
			{language === "dutch" ? (
				<Box
					sx={{
						padding: {
							xs: "0px 20px 0px 20px",
							sm: "150px 30px 200px 20px",
						},
					}}
					className='programma_body'
				>
					<div className='programma_header-container'>
						<div className='programma_header-background'></div>
						<h2 className='programma_header'>Programma</h2>
					</div>

					<Box
						sx={{
							maxWidth: "100%",
							height: "300px",
							margin: "40px 0px 25px 0px",
							overflow: "hidden",
							borderRadius: "10px",

							backgroundImage:
								"url(https://upload.wikimedia.org/wikipedia/commons/b/b3/Salentein2.jpg)",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							opacity: "90%",
							position: "relative",
							zIndex: "-1",
						}}
					></Box>

					<h3 className='small-header' id='locatie'>
						Tijdsschema:
					</h3>
					<div className='programma_grid'>
						{userData && userData.dayGuest && (
							<>
								<div className='programma_grid-time'>14:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Aankomst gasten</span>
								</div>
								<div className='programma_grid-time'>15:00</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<FavoriteIcon />
									</span>
									<span>Start ceremonie</span>
									<span className='programma_grid-subtitle'>
										De ceremonie vindt plaats in de buitenlucht.
									</span>
								</div>
								<div className='programma_grid-time'>16:00</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<WineBarIcon />
									</span>
									<span>Toost & Borrel</span>
								</div>
								<div className='programma_grid-time'>17:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<RestaurantIcon />
									</span>
									<span>Diner</span>
									<span className='programma_grid-subtitle'>
										Ook het diner vindt plaats in de buitenlucht. Zorg dat je
										eventueel iets warms bij je hebt.
									</span>
								</div>
							</>
						)}
						{userData && userData.dayGuest ? (
							<>
								<div className='programma_grid-time'>20:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Aankomst avondgasten</span>
								</div>
							</>
						) : (
							<>
								<div className='programma_grid-time'>20:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Aankomst gasten</span>
								</div>
							</>
						)}
						<div className='programma_grid-time'>21:00</div>
						<div className='programma_grid-item'>
							<span className='programma_grid-icon'>
								<CelebrationIcon />
							</span>
							<span>Party time!</span>
							<span className='programma_grid-subtitle'>
								Het feest vindt binnen plaats.
							</span>
							<span className='programma_grid-subtitle'>
								Wil je je{" "}
								<Link
									to={
										"https://www.instagram.com/reel/CqZKT21DQnM/?igshid=MzRlODBiNWFlZA=="
									}
									target='blank'
								>
									<b>danspasjes</b>
								</Link>{" "}
								vast oefenen? Voel je vrij!
							</span>
						</div>
						<div className='programma_grid-time'>00:30</div>
						<div className='programma_grid-item'>
							<span className='programma_grid-icon'>
								<NightsStayIcon />
							</span>
							<span>Laatste dans</span>
							<span className='programma_grid-subtitle'>
								Rond middernacht zal het bruidspaar vertrekken. Gasten hebben
								dan nog kort de gelegenheid om door te feesten.
							</span>
						</div>
					</div>
					{userData && userData.dayGuest && (
						<p className='programma_paragraph'>
							Er zal tijdens het hele programma een fotograaf aanwezig zijn. Wij
							stellen het daarom op prijs als jij je telefoon gedurende de
							ceremonie in je zak wilt houden.
						</p>
					)}
				</Box>
			) : (
				<Box
					sx={{
						padding: {
							xs: "0px 20px 0px 20px",
							sm: "150px 30px 200px 20px",
						},
					}}
					className='programma_body'
				>
					<div className='programma_header-container'>
						<div className='programma_header-background'></div>
						<h2 className='programma_header'>Programme</h2>
					</div>

					<Box
						sx={{
							maxWidth: "100%",
							height: "300px",
							margin: "40px 0px 25px 0px",
							overflow: "hidden",
							borderRadius: "10px",

							backgroundImage:
								"url(https://upload.wikimedia.org/wikipedia/commons/b/b3/Salentein2.jpg)",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							opacity: "90%",
							position: "relative",
							zIndex: "-1",
						}}
					></Box>

					<h3 className='small-header' id='locatie'>
						Time schedule:
					</h3>
					<div className='programma_grid'>
						{userData && userData.dayGuest && (
							<>
								<div className='programma_grid-time'>14:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Guests arrive</span>
								</div>
								<div className='programma_grid-time'>15:00</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<FavoriteIcon />
									</span>
									<span>Ceremony starts</span>
									<span className='programma_grid-subtitle'>
										The ceremony will take place outdoors.
									</span>
								</div>
								<div className='programma_grid-time'>16:00</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<WineBarIcon />
									</span>
									<span>Toast & Reception</span>
								</div>
								<div className='programma_grid-time'>17:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<RestaurantIcon />
									</span>
									<span>Dinner</span>
									<span className='programma_grid-subtitle'>
										The dinner also takes place outdoors, so be prepared and
										bring something warm in case it gets chilly at night.
									</span>
								</div>
							</>
						)}
						{userData && userData.dayGuest ? (
							<>
								<div className='programma_grid-time'>20:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Arrival evening guests</span>
								</div>
							</>
						) : (
							<>
								<div className='programma_grid-time'>20:30</div>
								<div className='programma_grid-item'>
									<span className='programma_grid-icon'>
										<DirectionsCarIcon />
									</span>
									<span>Guests arrive</span>
								</div>
							</>
						)}
						<div className='programma_grid-time'>21:00</div>
						<div className='programma_grid-item'>
							<span className='programma_grid-icon'>
								<CelebrationIcon />
							</span>
							<span>Party time!</span>
							<span className='programma_grid-subtitle'>
								The party will take place indoors.
							</span>
							<span className='programma_grid-subtitle'>
								Want to start practicing your{" "}
								<Link
									to={
										"https://www.instagram.com/reel/CqZKT21DQnM/?igshid=MzRlODBiNWFlZA=="
									}
									target='blank'
								>
									<b>dance moves?</b>
								</Link>{" "}
								Feel free!
							</span>
						</div>
						<div className='programma_grid-time'>00:30</div>
						<div className='programma_grid-item'>
							<span className='programma_grid-icon'>
								<NightsStayIcon />
							</span>
							<span>Last dance</span>
							<span className='programma_grid-subtitle'>
								The bride and groom will leave around midnight. Guests have a
								little more time to continue celebrating.
							</span>
						</div>
					</div>

					{userData && userData.dayGuest && (
						<p className='programma_paragraph'>
							There will be a photographer all through the day. We would
							therefore appreciate it if you could keep your phone in your
							pocket during the ceremony.
						</p>
					)}
				</Box>
			)}

			<Box
				sx={{
					padding: {
						xs: "0px 35px 100px 35px",
						sm: "0px 70px 50px 70px",
					},
				}}
			></Box>
		</Layout>
	);
};
