import Menu from "../components/Menu";
import RegisterSide from "../components/RegisterSide";

export const RegisterScreen = () => {
	return (
		<div>
			<RegisterSide />
		</div>
	);
};
