import Grid from "@mui/material/Grid";
import {useAuth} from "../provider/AuthProvider";
import LanguageIcon from "@mui/icons-material/Language";
import "./InformatieScreen.css";
import PlaceIcon from "@mui/icons-material/Place";
import {Link, useNavigate} from "react-router-dom";
import useNotification from "../hooks/useNotification";
import Menu from "../components/Menu";
import MapBox from "../components/MapBox";
import {Box} from "@mui/material";
import {Image} from "@mui/icons-material";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import PinDropIcon from "@mui/icons-material/PinDrop";
import HotelIcon from "@mui/icons-material/Hotel";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

export const InformatieScreen = () => {
	const {addNotification} = useNotification();
	const {currentUser, fetchedUserData} = useAuth();

	const navigate = useNavigate();

	return (
		<div>
			<div className='page2'>
				<Grid container component='main'>
					<Grid
						item
						xs={12}
						sm={4}
						md={7}
						lg={8}
						sx={{
							minHeight: {xs: "30vh", sm: "100vh"},
							height: {sm: "100vh"},

							backgroundColor: "#fffaeb",
						}}
					>
						<Box
							sx={{
								backgroundImage: {
									xs: "url(images/landingpage0.5.jpg)",

									sm: "url(images/landingpage.jpg)",
								},
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								backgroundPosition: {
									sm: "40%",
									md: "center",
								},
								position: {
									xs: "relative",
									sm: "fixed",
								},
								width: {
									xs: "100vw",
									sm: "32vw",
									md: "57vw",
									lg: "66vw",
								},
								height: {
									xs: "30vh",
									sm: "100vh",
									md: "100vh",
									lg: "100vh",
								},
							}}
							id='imagebox'
						></Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={8}
						md={5}
						lg={4}
						sx={{
							width: {
								xs: "100vw",
								sm: "67vw",
								md: "42vw",
								lg: "33vw",
							},

							backgroundColor: "#fffaeb",
							zIndex: 10,
						}}
						id='gridright'
					>
						<Box
							sx={{
								padding: {
									xs: "0px 20px 0px 20px",
									sm: "0px 70px 0px 70px",
								},
							}}
						>
							<h1 className='header' id='informatie'>
								Praktische Informatie
							</h1>
							<h3 className='small-header' id='locatie'>
								<PinDropIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Locatie:
							</h3>
						</Box>

						<Box
							sx={{
								maxWidth: "100%",
								height: "300px",
								margin: "25px 20px",
								overflow: "hidden",
								borderRadius: "10px",

								backgroundImage: "url(images/locatie-salentein.jpg)",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								opacity: "90%",
							}}
						></Box>

						<Box
							sx={{
								padding: {
									xs: "0px 20px 0px 20px",
									sm: "0px 70px 30px 70px",
								},
							}}
						>
							<p className='paragraph'>
								Wij gaan elkaar het jawoord geven op Landgoed de Salentein in
								Nijkerk.
							</p>
							<div className='information-detail'>
								<LanguageIcon />
								<Link to={"https://landgoeddesalentein.nl/"} target='blank'>
									Landgoed de Salentein
								</Link>
							</div>
							<div className='information-detail'>
								<PlaceIcon />
								<Link
									to={"https://goo.gl/maps/gTP9FDoBycWZ2Pub8"}
									target='blank'
								>
									Putterstraatweg 5 - 9, 3862 RA Nijkerk, Nederland
								</Link>
							</div>
						</Box>

						<Box
							sx={{
								padding: {
									xs: "0px 35px 100px 35px",
									sm: "0px 70px 200px 70px",
								},
							}}
						>
							<p className='paragraph'>
								Voor algemene informatie over de bereikbaarheid van de Salentein
								verwijzen wij jullie graag door naar hun{" "}
								<Link to={"https://landgoeddesalentein.nl/"} target='blank'>
									website
								</Link>
								.
							</p>

							<Box
								sx={{
									padding: "0px 20px 30px 20px",
								}}
							>
								<MapBox />
							</Box>
							<h3 className='small-header' id='parkeren'>
								<DirectionsCarIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Parkeren
							</h3>
							<p className='paragraph'>
								Op onze trouwlocatie is genoeg parkeergelegenheid. Je kunt je
								auto daar ‘s nachts ook laten staan, en hem de volgende dag weer
								ophalen (bevestigen met de Salentein).
							</p>
							<h3 className='small-header'>
								<DirectionsTransitIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Openbaar vervoer
							</h3>

							<p className='paragraph'>
								Met het openbaar vervoer kun je gemakkelijk tot het treinstation
								in Nijkerk komen. Het vervoer van het treinstation naar de
								Salentein is onregelmatiger, en er zijn geen ov-fietsen
								beschikbaar. Wij raden jullie aan jullie reis vooraf te plannen,
								eventueel te carpoolen en / of een taxiritje te boeken bij
								Taxibedrijf Boute (a xxx voor een reguliere taxi, en xxx voor
								een busje voor xxx personen). Vanaf het station in Nijkerk is
								het nog 5 minuten rijden naar de trouwlocatie.
							</p>
							<p className='paragraph'>
								‘s Avonds gaat de laatste trein van Nijkerk naar Utrecht om
								00:34. Om van de Salentein naar het station, naar een hotel of
								naar huis te komen, kunnen jullie weer gebruik maken van
								Taxibedrijf Boute.
							</p>

							<h3 className='small-header' id='overnachting'>
								<HotelIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Overnachting:
							</h3>
							<p className='paragraph'>
								Het is mogelijk om in de buurt van onze trouwlocatie te
								overnachten. Wij hebben een blokreservering gemaakt bij het
								dichtsbijzijnde hotel:
							</p>
							<h3 className='text-emphasis'>Het Ambt van Nijkerk</h3>
							<p className='paragraph'>Loopafstand ca. 20 minuten.</p>
							<p className='paragraph'>
								Als je bij het boeken van een kamer
								<span className='bold'> referentienummer 138143 </span>
								doorgeeft, kom je in onze blokreservering terecht.
							</p>
							<h4 className='small-header4'>Kosten voor een kamer zijn:</h4>
							<p className='paragraph'>
								Overnachting in het hotel is voor eigen rekening. Wij hebben
								daarvoor wel tarieven afgesproken:
							</p>
							<p className='paragraph'>
								Tweepersoonskamers: 114 euro incl. ontbijt. Eenpersoonskamers a
								104 euro incl. ontbijt.
							</p>
							<p className='paragraph'>
								Familiekamers zijn ook beschikbaar op aanvraag
							</p>
							{/* <p className='paragraph'>Het dichtsbijzijnde hotel is:</p> */}

							<h3 className='small-header' id='dresscode'>
								<CheckroomIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Dresscode
							</h3>
							<span className='dresscode'>Summer chic</span>
							<h3 className='small-header'>
								<RecordVoiceOverIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Speeches
							</h3>
							<p>
								vinden we leuk aangewezen momenten contact met ceremoniemeesters
							</p>

							<h3 className='small-header'>
								<CardGiftcardIcon
									sx={{marginRight: "20px", transform: "translateY(3px)"}}
								/>
								Cadeautip
							</h3>
							<p className='paragraph'>
								Er is niets dat ons gelukkiger kan maken dan jullie aanwezigheid
								op onze bruiloft. Toch weten wij ook dat velen van jullie de
								gelegenheid zullen willen aangrijpen om ons een cadeau te geven.
								Wij willen jullie dus onderdeel maken van onze droom om een jaar
								vrij te nemen om een lange fietstocht te maken. Een envelopje
								brengt ons iets dichter bij deze droom in de buurt!
							</p>
							<h3 className='small-header'>Contactgegevens</h3>

							<p className='paragraph'>
								Heb je vragen of wil je iets doorgeven? Neem dan gerust contact
								op met onze ceremoniemeesters! Sandra en Rick zijn bereikbaar
								via het e-mailadres ceremoniemeesters@irisenrobin.nl (TBC)
							</p>
						</Box>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
