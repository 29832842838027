import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {redirect, useNavigate} from "react-router-dom";
import {functions} from "../helpers/FirebaseInit";
import LoadingButton from "@mui/lab/LoadingButton";
import {sanitizeName} from "../helpers/FormValidation";

import useNotification from "../hooks/useNotification";
import {useAuth} from "../provider/AuthProvider";
import {httpsCallable} from "firebase/functions";
import {
	checkPasswordCriteria,
	hasLowerAndUpper,
	hasNumber,
	hasSpecChar,
	isBetween,
	isValidEmail,
	isValidName,
	isEmpty,
	validOtp,
} from "../helpers/FormValidation";
import {
	Fade,
	FilledInput,
	FormControl,
	Grow,
	IconButton,
	InputAdornment,
	InputLabel,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
	GreenFormControl,
	GreenTextField,
	StyledButton,
	StyledLoadingButton,
} from "./ThemedComponents";
import {useLanguage} from "../provider/LanguageProvider";

const theme = createTheme();

export default function RegisterSide() {
	const {login} = useAuth();
	const navigate = useNavigate();
	const {addNotification, removeNotification} = useNotification();
	const {language} = useLanguage();
	const [name, setName] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [confirmPassword, setConfirmPassword] = React.useState("");
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [otp, setOtp] = React.useState("");
	const [validationActivated, setValidationActivated] = React.useState(false);
	const [otpValidationActivated, setOtpValidationActivated] =
		React.useState(false);
	const [formReadyForSubmit, setFormReadyForSubmit] = React.useState(false);
	const [buttonLoading, setButtonLoading] = React.useState(false);
	const [otpPhase, setOtpPhase] = React.useState(true);

	const [accountPhase, setAccountPhase] = React.useState(false);

	React.useEffect(() => {
		checkFormReady();
	});

	const nextPhase = () => {
		setOtpValidationActivated(true);
		if (validOtp(otp)) {
			setOtpPhase((prev) => !prev);
			setTimeout(() => {
				setAccountPhase((prev) => !prev);
			}, 300);
		} else {
			addNotification(
				"Controleer of u de juiste code heeft ingevuld alstublieft.",
				"info"
			);
		}
	};
	const prevPhase = () => {
		setOtpPhase((prev) => !prev);
		setAccountPhase((prev) => !prev);
	};

	const handleEnter = (event) => {
		event.preventDefault();
		if (!accountPhase) {
			nextPhase();
		}
		if (accountPhase && event.key === "Enter") {
			handleSubmit(event);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setValidationActivated(true);
		if (formReadyForSubmit) {
			setButtonLoading(true);
			try {
				const sanitizedName = sanitizeName(name);
				const functionName =
					process.env.REACT_APP_environmentMode === "development"
						? "createUserDevelop"
						: "createUser";
				const createUser = httpsCallable(functions, `${functionName}`);
				createUser({
					username: `${sanitizedName}`,
					password,
					otp: `${otp}`,
				})
					.then(function (response) {
						if (response.data.result === "success") {
							addNotification(
								"Uw account is aangemaakt, log in alstublieft.",
								"success"
							);
							navigate("/login");
						}
					})
					.catch(function (error) {
						setButtonLoading(false);
						console.log(error.code);
						if (error.code === "functions/not-found") {
							addNotification(
								"Uw cijfercode was onjuist, probeert u het nog eens.",
								"error"
							);
						} else if (error.code === "functions/failed-precondition") {
							addNotification(
								"Uw cijfercode is niet geldig, neem contact op.",
								"error"
							);
						} else if (error.code === "functions/already-exists") {
							addNotification(
								"Deze gebruikersnaam is niet beschikbaar.",
								"error"
							);
						} else {
							addNotification("Oeps, er is een fout opgetreden.", "error");
						}
						return;
					});
			} catch (e) {
				console.log(e);
			}
		}
	};

	const checkFormReady = React.useCallback(() => {
		if (checkPasswordCriteria(password, confirmPassword)) {
			if (isValidName(name)) {
				if (validOtp(otp)) {
					setFormReadyForSubmit(true);
					return true;
				}
			}
		}
		setFormReadyForSubmit(false);
		return false;
	}, [password, name, confirmPassword, otp]);

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				component='main'
				sx={{height: "100vh", overflow: "hidden"}}
			>
				<Grid
					item
					xs={12}
					sm={4}
					md={7}
					lg={8}
					sx={{
						minHeight: {xs: "30vh", sm: "100vh"},
						height: {sm: "100vh"},

						backgroundColor: "#fffaeb",
					}}
				>
					<Box
						sx={{
							backgroundImage: {
								xs: "url(images/landingpage0.5.jpg)",

								sm: "url(images/landingpage.jpg)",
							},
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: {
								sm: "40%",
								md: "center",
							},
							position: {
								xs: "relative",
								sm: "fixed",
							},
							width: {
								xs: "100vw",
								sm: "32vw",
								md: "57vw",
								lg: "66vw",
							},
							height: {
								xs: "30vh",
								sm: "100vh",
								md: "100vh",
								lg: "100vh",
							},
						}}
						id='imagebox'
					></Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					lg={4}
					sx={{
						width: {
							xs: "100vw",
							sm: "67vw",
							md: "42vw",
							lg: "33vw",
						},

						backgroundColor: "#fffaeb",
						zIndex: 10,
					}}
					id='gridright'
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Avatar sx={{m: 1, bgcolor: "#5d5f20"}}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component='h1' variant='h5'></Typography>
						<Box
							component='form'
							noValidate
							onSubmit={handleEnter}
							sx={{mt: 1}}
						>
							<Grow in={otpPhase} unmountOnExit>
								<div>
									<GreenTextField
										error={otpValidationActivated && !validOtp(otp)}
										margin='normal'
										fullWidth
										id='OTP'
										label={
											language === "dutch" ? "Cijfercode" : "Personal code"
										}
										name='OTP'
										autoFocus
										variant='filled'
										value={otp}
										onChange={(event) => setOtp(event.target.value)}
									/>
									<StyledButton
										fullWidth
										variant='contained'
										sx={{mt: 3, mb: 2}}
										onClick={() => nextPhase()}
									>
										{language === "dutch" ? "Volgende" : "Next"}
									</StyledButton>
									<Grid container>
										<Grid item xs>
											<Link
												href='mailto:help@robineniris.nl'
												variant='body2'
												sx={{color: "#6e702d"}}
											>
												{language === "dutch"
													? "Lukt het niet?"
													: "Any problems?"}
											</Link>
										</Grid>
									</Grid>
								</div>
							</Grow>

							{accountPhase && (
								<Grow in={accountPhase}>
									<div>
										<GreenTextField
											error={validationActivated && !isValidName(name)}
											margin='normal'
											fullWidth
											id='email'
											label={
												language === "dutch" ? "Gebruikersnaam" : "Username"
											}
											name='email'
											autoFocus
											variant='filled'
											value={name}
											onChange={(event) => setName(event.target.value)}
											sx={{marginBottom: "15px"}}
										/>

										<GreenFormControl
											variant='filled'
											fullWidth
											sx={{marginBottom: "15px"}}
										>
											<InputLabel htmlFor='filled-adornment-password'>
												{language === "dutch" ? "Wachtwoord" : "Password"}
											</InputLabel>
											<FilledInput
												id='filled-adornment-password'
												type={showPassword ? "text" : "password"}
												fullWidth
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={() => setShowPassword(!showPassword)}
															edge='end'
															tabIndex={-1}
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
												onChange={(event) => setPassword(event.target.value)}
												value={password}
											/>
										</GreenFormControl>

										<GreenFormControl variant='filled' fullWidth>
											<InputLabel htmlFor='filled-adornment-confirmpassword'>
												{language === "dutch"
													? "Herhaal wachtwoord"
													: "Repeat password"}
											</InputLabel>
											<FilledInput
												error={
													validationActivated &&
													!checkPasswordCriteria(password, confirmPassword)
												}
												id='filled-adornment-confirmpassword'
												type={showConfirmPassword ? "text" : "password"}
												fullWidth
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={() =>
																setShowConfirmPassword(!showConfirmPassword)
															}
															edge='end'
															tabIndex={-1}
														>
															{showConfirmPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
												onChange={(event) =>
													setConfirmPassword(event.target.value)
												}
												value={confirmPassword}
												onKeyUp={handleEnter}
											/>
										</GreenFormControl>
										<Box sx={{display: "flex"}}>
											<StyledButton
												onClick={() => prevPhase()}
												variant='filled'
												sx={{
													mt: 3,
													mb: 2,
													flexGrow: "1",
													marginRight: "10px",
													color: "#5d5f20",
												}}
											>
												{language === "dutch" ? "Terug" : "Go back"}
											</StyledButton>

											<StyledLoadingButton
												variant='contained'
												loading={buttonLoading}
												sx={{mt: 3, mb: 2, flexGrow: "2"}}
												onClick={handleSubmit}
											>
												{language === "dutch" ? "registreer" : "Register"}
											</StyledLoadingButton>
										</Box>
										<Grid container>
											<Grid item xs>
												<Link
													href='mailto:help@irisenrobin.nl'
													variant='body2'
													sx={{color: "#6e702d"}}
												>
													{language === "dutch"
														? "Lukt het niet?"
														: "Encountering a problem?"}
												</Link>
												<Link
													href='/login'
													variant='body2'
													sx={{color: "#6e702d", marginLeft: "10px"}}
												>
													{language === "dutch"
														? "Al een account?"
														: "Already have an account?"}
												</Link>
											</Grid>
										</Grid>
									</div>
								</Grow>
							)}

							<Typography
								variant='body2'
								sx={{color: "red", fontSize: "12px"}}
								gutterBottom
							>
								{validationActivated && !isValidName(name) && (
									<Typography variant='caption' display='block' gutterBottom>
										{language === "dutch"
											? "Zorg dat u een naam invuld en geen e-mail, dat deze geen cijfers bevat en bestaat uit 6 tot 25 karakters."
											: "Make sure that you have entered a name, that contains no numbers en has 6 to 25 charachters."}
									</Typography>
								)}
								{validationActivated &&
									!checkPasswordCriteria(password, confirmPassword) && (
										<Typography variant='caption' display='block' gutterBottom>
											{language === "dutch"
												? "Zorg dat de wachtwoorden overeenkomen."
												: "Make sure both passwords match."}
										</Typography>
									)}
								{validationActivated && !validOtp(otp) && (
									<Typography variant='caption' display='block' gutterBottom>
										{language === "dutch"
											? "Zorg dat u uw cijfercode correct invult."
											: "Make sure you have correctly entered the personal code."}
									</Typography>
								)}
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
