import {Box} from "@mui/material";

import {Layout} from "../../components/Layout";
import {Link} from "react-router-dom";
import MapBox from "../../components/MapBox";

import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import {useLanguage} from "../../provider/LanguageProvider";
import {useAuth} from "../../provider/AuthProvider";

export const LocatieScreen = () => {
	const {userData} = useAuth();
	const {language} = useLanguage();
	return (
		<Layout>
			<div className='locatie_header-container'>
				<div className='locatie_header-background'></div>
				<h2 className='locatie_header'>
					{language === "dutch" ? "Locatie" : "Location"}
				</h2>
			</div>
			<Box
				sx={{
					padding: {
						xs: "0px 20px 0px 20px",
						sm: "150px 30px 0px 30px",
					},
				}}
			>
				<Box
					sx={{
						marginTop: "40px",
					}}
				></Box>
			</Box>

			<Box
				sx={{
					maxWidth: "100%",
					height: "300px",
					margin: "25px 20px 25px 10px",
					overflow: "hidden",
					borderRadius: "10px",

					backgroundImage: "url(images/locatie-salentein.jpg)",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					opacity: "90%",
					position: "relative",
					zIndex: "-1",
				}}
			></Box>

			<Box
				sx={{
					padding: {
						xs: "0px 20px 0px 20px",
						sm: "0px 30px 30px 30px",
					},
				}}
			>
				{userData && userData.dayGuest && language === "dutch" && (
					<p className='paragraph'>
						Wij gaan elkaar het jawoord geven op Landgoed de Salentein in
						Nijkerk. Ook het diner en het feest vinden daar plaats.
					</p>
				)}
				{userData && userData.dayGuest && language !== "dutch" && (
					<p className='paragraph'>
						Our wedding ceremony, dinner and festivities will take place at
						Landgoed de Salentein in the city of Nijkerk.
					</p>
				)}
				{userData && !userData.dayGuest && language === "dutch" && (
					<p className='paragraph'>
						Ons trouwfeest vindt plaats op Landgoed de Salentein in Nijkerk.
					</p>
				)}
				{userData && !userData.dayGuest && language !== "dutch" && (
					<p className='paragraph'>
						We will have our wedding ceremony and festivities at Landgoed de
						Salentein in Nijkerk.
					</p>
				)}

				<div className='information-detail'>
					<LanguageIcon />
					<Link to={"https://landgoeddesalentein.nl/"} target='blank'>
						Landgoed de Salentein
					</Link>
				</div>
				<div className='information-detail'>
					<PlaceIcon />
					<Link to={"https://goo.gl/maps/gTP9FDoBycWZ2Pub8"} target='blank'>
						Putterstraatweg 5 - 9, 3862 RA Nijkerk, Nederland
					</Link>
				</div>
			</Box>

			<Box
				sx={{
					padding: {
						xs: "0px 10px 100px 10px",
						sm: "0px 30px 200px 30px",
					},
				}}
			>
				{language === "dutch" ? (
					<p className='paragraph'>
						Algemene informatie over de bereikbaarheid van Landgoed de Salentein
						kunnen jullie vinden op hun{" "}
						<Link to={"https://landgoeddesalentein.nl/"} target='blank'>
							website
						</Link>
						, en via het kopje 'Parkeren en OV' in het menu.
					</p>
				) : (
					<p className='paragraph'>
						For general information on this location and how to reach it, we
						refer you to their{" "}
						<Link to={"https://landgoeddesalentein.nl/"} target='blank'>
							website
						</Link>
						.
					</p>
				)}

				<Box
					sx={{
						padding: "0px 0px 0px 0px",
						position: "relative",
						zIndex: "-1",
					}}
				>
					<MapBox />
				</Box>
			</Box>

			<Box
				sx={{
					padding: {
						xs: "0px 35px 100px 35px",
						sm: "0px 30px 150px 30px",
					},
				}}
			></Box>
		</Layout>
	);
};
